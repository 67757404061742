var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:[
    'nav',
    _vm.$options.name,
    { menuOpen: _vm.menuOpen },
    { inverted: _vm.inverted }
  ]},[_c('WNavTopbar',{attrs:{"menu-open":_vm.menuOpen,"inverted":_vm.inverted},on:{"burger-click":_vm.burgerClick,"close":_vm.closeMenu}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.menuOpen)?_c('div',{staticClass:"overlay-darken",on:{"click":_vm.closeMenu}}):_vm._e()]),_vm._v(" "),(_vm.pageLoaded)?_c('div',{ref:"sidebar",class:[
      'nav-sidebar',
      { 'nav-sidebar--open': _vm.menuOpen },
      _vm.parentHasLayoutAstra ? 'astra-theme' : ''
    ]},[_c('WNavPrimary',{attrs:{"listen-to-folder":_vm.triggerFolderClose,"nav":_vm.nav},on:{"close":_vm.closeMenu}}),_vm._v(" "),_c('WNavSecondary',{attrs:{"footer":_vm.footer},on:{"cookie-popup-open":_vm.openCookiePopup,"close":_vm.closeMenu}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }