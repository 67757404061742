import { render, staticRenderFns } from "./TerraXNoResultsGlobe.vue?vue&type=template&id=05b143da&scoped=true&"
import script from "./TerraXNoResultsGlobe.vue?vue&type=script&lang=js&"
export * from "./TerraXNoResultsGlobe.vue?vue&type=script&lang=js&"
import style0 from "./TerraXNoResultsGlobe.vue?vue&type=style&index=0&id=05b143da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b143da",
  null
  
)

export default component.exports