<template>
  <div class="copyright">
    <img
      src="/icons/logo-sustainable-markets-seal-version-inverted.png"
      width="50"
      height="54.8"
      alt="Sustainable Markets logo"
      class="copyright__logo"
    />
    <p class="copyright__txt">
      © {{ new Date().getFullYear() }}
      {{ txt }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  props: {
    txt: {
      type: String,
      default: "Sustainable Markets Initiative"
    }
  }
};
</script>

<style lang="scss">
.copyright {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @extend %margin-top-bottom-x1;
  @media (min-width: $break-footer) {
    flex-direction: row;
    flex: 0;
  }
  &__logo {
    width: 24rem;
    height: auto;
  }
  &__txt {
    @extend %body2;
    display: block;
    white-space: nowrap;
    margin-top: 1.5rem;
    color: currentColor;
    @media (min-width: $break-footer) {
      margin-left: 6rem;
    }
  }
}
</style>
