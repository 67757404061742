import GoTrue from 'gotrue-js';

// Instantiate the GoTrue auth client with an optional configuration
export default ({
  app,
  route
}, inject) => {
  const auth = new GoTrue({
    APIUrl: 'https://www.sustainable-markets.org/.netlify/identity',
    audience: '',
    setCookie: true,
  });

  inject('auth', auth);
}
