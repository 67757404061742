<template>
  <transition name="fade">
    <div v-if="show" class="loading-bar" :style="{
      '--duration-started': durationStarted + 'ms',
      '--duration-ended': durationEnded + 'ms',
    }">
      <div class="loading-bar__progress" :class="{
        'loading-bar__progress--inverted': $store.state.layout == 'LayoutTerraXFind',
        'loading-bar__progress--started': started,
        'loading-bar__progress--ended': ended,
      }"></div>
    </div>
  </transition>
</template>
<script>
  export default {
    name: 'I-LoadingBar',
    data: () => ({
      show: false,
      started: false,
      ended: false,
      timeout: null,
      durationEnded: 250,
      durationStarted: 10000,
    }),
    methods: {
      start() {
        this.show = true
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.started = true
        }, 10)
      },
      finish() {
        this.started = false
        this.ended = true
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.show = false
          this.ended = false
        }, this.durationEnded)
      },
    },
  }
</script>
<style lang="scss" scoped>
.loading-bar {
  --duration-started: 10000ms;
  --duration-ended: 250ms;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 3px;
  z-index: 1000;
  overflow: hidden;
  &__progress {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-blue;
    transition: transform 250ms cubic-bezier($ease-in-out-cubic), background-color 250ms;
    transform: scaleX(0);
    transform-origin: left;
    &--inverted {
      @media (min-width: $break-nav) {
        background-color: $color-white;
      }
    }
    &--started {
      transition: transform var(--duration-started) cubic-bezier($ease-out-quad);
      transform: scaleX(0.75);
    }
    &--ended {
      transition: transform var(--duration-ended) cubic-bezier($ease-in-quad);
      transform: scaleX(1);
    }
  }
}
</style>
