<template>
  <section :class="[$options.name, {fillBlue: fillBlue, margins: margins, paddings: paddings}]">
    <slot></slot>
  </section>
</template>

<script>

export default {
  name: "W-Section",
  components: {
  },
  props: {
    margin: {
      type: Boolean,
      required: false,
      default: false,
    },
    fillBlue: {
      type: Boolean,
      required: false,
      default: false,
    },
    paddings: {
      type: Boolean,
      required: false,
      default: true,
    },
    margins: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {},
  mounted() {
  },
  watch: {},
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.W-Section{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.fillBlue{
    background-color: $color-blue;
    //@extend %padding-top-bottom-x1;
  }
  &.paddings{
    @extend %padding-top-bottom-x1;
  }
  &.margins{
    //@extend %margin-top-bottom-x1;
  }
}
</style>
