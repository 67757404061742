import server from "~/helpers/server.js";

export const state = () => ({
  navLinks: [],
  footer: {},
  gdpr: {},
  datasources: {},
  terraX: {},
  layout: "default",
  isNotInitialPageLoad: false,
})
export const getters = {
  getLayout: (state) => (props) => {
    return state.layout
  },
}
export const mutations = {
  setNavigation: (state, payload) => {
    let nav = payload.nav;
    if (!payload.buildTerraX) {
      nav = payload.nav.filter((item) => !item.title.includes('Terra X'))
    }
    state.navLinks = nav
  },
  setFooter: (state, footer) => (state.footer = footer),
  setGdpr: (state, gdpr) => (state.gdpr = gdpr),
  setDatasources: (state, datasources) => (state.datasources = datasources),
  setTerraX: (state, terraX) => (state.terraX = terraX),
  setLayout: (state, payload) => (state.layout = payload),
  setIsNotInitialPageLoad: (state, payload) => (state.isNotInitialPageLoad = payload)
}

export const actions = {
  async editorData({
    commit
  }, context) {
    if (process.client) {
      const version = "draft";

      const navigation = await server.api(this, version, "global/navigation");
      const footer = await server.api(this, version, "global/footer");
      const gdpr = await server.api(this, version, "global/gdpr");
      const datasources = await server.getDatasources(this, version);

      await commit('setNavigation', {
        nav: navigation.content.navList,
        buildTerraX: true
      });
      await commit('setFooter', footer);
      await commit('setGdpr', gdpr);
      await commit('setDatasources', datasources);


      // const terraXProjects = await server.getAllTerraX(this, version, context.$config.terraXProjects);
      // const terraXInvestors = await server.getAllTerraX(this, version, context.$config.terraXInvestors);
      // const terraXInsurence = await server.getAllTerraX(this, version, context.$config.terraXInsurence);

      // await commit('terrax/setTerraXProjects', terraXProjects);
      // await commit('terrax/setTerraXInvestors', terraXInvestors);
      // await commit('terrax/setTerraXInsurence', terraXInsurence);
    }
  },
  async nuxtServerInit({
    commit
  }, context) {
    const version = server.getVersion(context);

    const navigation = await server.api(context.app, version, "global/navigation", context);

    const footer = await server.api(context.app, version, "global/footer", context);
    const gdpr = await server.api(context.app, version, "global/gdpr", context);
    const datasources = await server.getDatasources(context.app, version);

    await commit('setNavigation', {
      nav: navigation.content.navList,
      buildTerraX: context.$config.buildTerraX
    });
    await commit('setFooter', footer);
    await commit('setGdpr', gdpr);
    await commit('setDatasources', datasources);
  },
}
