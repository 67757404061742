<template>
  <div class="terra-x-globe-position-outer">
    <div
      class="terra-x-globe-position"
      :class="{
        'filters-open': isFilterOpen,
        'filters-closed': !isFilterOpen,
        about: isAboutPage,
        initial: initial,
      }"
      :style="{
        '--offset-left-outer-filters-closed': offsetLeftOuterFiltersClosed,
        '--offset-left-outer-filters-open': offsetLeftOuterFiltersOpen,
        '--offset-left-outer-about': offsetLeftOuterAbout,
        '--offset-top-filters-open-mobile': offsetTopFiltersOpenMobileMobile,
      }"
    >
      <div class="terra-x-globe-position__wrapper">
        <TerraXNoResultsGlobe />
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import TerraXNoResultsGlobe from "@/components/terra-x/TerraXNoResultsGlobe.vue";
export default {
  name: "TerraXGlobePosition",
  data() {
    return {
      initial: true,
    };
  },
  components: {
    TerraXNoResultsGlobe,
  },
  computed: {
    isAboutPage() {
      return this.$store.state.layout === "LayoutTerraX";
    },
    isFilterOpen() {
      return this.$store.getters["terrax/isFilterOpen"]();
    },
    globePos() {
      return this.$store.state.terrax.globePosition;
    },
    offsetTopFiltersOpenMobileMobile() {
      return this.$store.state.terrax.globeFromTop + "px";
    },
    offsetLeftOuterFiltersOpen() {
      const offset =
        this.globePos["filters-open-left"] +
        (1 - this.globePos["filters-open-left"]) / 2;
      return offset * 100;
    },
    offsetLeftOuterFiltersClosed() {
      const offset =
        this.globePos["filters-closed-left"] +
        (1 - this.globePos["filters-closed-left"]) / 2;
      return offset * 100;
    },
    offsetLeftOuterAbout() {
      const offset =
        this.globePos["about-left"] + this.globePos["about-width"] / 2;
      return offset * 100;
    },
  },
  mounted() {
    setTimeout(() => {
      // initial globe placement calculated and finished, now we can enable animations
      this.initial = false;
    }, 350);
  },
};
</script>

<style lang="scss" scoped>
.terra-x-globe-position-outer {
  // this element is used for the transition when opening the main navigation
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: calc(100% + 2 * 5rem);
  height: 100%;
  overflow: hidden;
  z-index: 0;
  transition: translate 250ms linear;
}
.terra-x-globe-position {
  $terra-x-nav-height: 5rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: transform $globe-transition-duration-transform
      cubic-bezier($ease-in-out-cubic),
    opacity $globe-transition-duration-opacity;
  z-index: 2;

  &.filters-open {
    transition: transform 1.25s cubic-bezier($ease-in-out-cubic),
      opacity $globe-transition-duration-opacity;
  }
  &__wrapper {
    position: absolute;
    left: 0;
    transform: translateX(-50%) translateY(calc(2 * #{$terra-x-nav-height}));
    transition: transform $globe-transition-duration-transform
      cubic-bezier($ease-in-out-cubic);
    @media (orientation: landscape) {
      height: calc(100vh - #{$nav-height * 2} - #{$terra-x-nav-height});
      width: calc(100vh - #{$nav-height * 2} - #{$terra-x-nav-height});
      @media (min-width: $break-terra-x-nav) {
        height: calc(100vh - #{$nav-height * 2});
        width: calc(100vh - #{$nav-height * 2});
      }
      // top: calc(50vh - #{$nav-height * 0.75});
      // @media (min-width: $break-nav) {
      //   top: 50vh;
      // }

      .filters-open & {
        transform: translateX(-50%)
          translateY(
            calc(
              -50% + var(--offset-top-filters-open-mobile) - (2 * #{$terra-x-nav-height})
            )
          );
      }
    }

    @media (orientation: portrait) {
      height: 100vw;
      width: 100vw;
      .filters-open & {
        transform: translate(-50%);
      }
    }
    @media (orientation: portrait) and (min-width: $break-terra-x-nav) {
      top: calc(50vw + #{$nav-height});
      .filters-open & {
        transform: translate(
          -50%,
          calc(-80% + var(--offset-top-filters-open-mobile))
        );
      }
    }
  }
  // position based on: filters-closed placeholder
  transform: translateX(
    calc(var(--offset-left-outer-filters-closed) * 1.25 * 1%)
  );
  @media (min-width: $break-terra-x-nav) {
    transform: translateX(50%);
  }

  // position based on: filters-open placeholder
  &.filters-open {
    transform: translateX(
        calc(var(--offset-left-outer-filters-open) * 1.25 * 1%)
      )
      translateY(
        calc(
          0% + var(--offset-top-filters-open-mobile) -
            (0 * #{$terra-x-nav-height})
        )
      );
    @media (min-width: $break-terra-x-nav) {
      transform: translateX(calc(var(--offset-left-outer-filters-open) * 1%));
      .terra-x-globe-position__wrapper {
        transform: translateX(-50%) translateY(calc(2 * #{$terra-x-nav-height}));
      }
    }
  }

  // position based on: about placeholder
  &.about {
    transform: translateX(calc(var(--offset-left-outer-about) * 0.8 * 1%));
    transition: transform $globe-transition-duration-transform / 2
        $nav-sidebar-transition-speed cubic-bezier($ease-out-cubic),
      opacity $globe-transition-duration-opacity;
    .terra-x-globe-position__wrapper {
      // transform: translateX(-75%) translateY(-50%);
      transform: translateX(-75%) translateY(calc(2 * #{$terra-x-nav-height}));
    }
    @media (min-width: $break-nav) {
      transform: translateX(calc(var(--offset-left-outer-about) * 1%));
      .terra-x-globe-position__wrapper {
        transform: translateX(-50%) translateY(calc(2 * 5rem));
      }
    }
    &.hide {
      transform: translateX(-25%);
      transition: transform 0.5s cubic-bezier($ease-in-cubic),
        opacity $globe-transition-duration-opacity;
    }
  }

  &.initial {
    transition-duration: 0s;
    opacity: 0;
  }
}
</style>
