<template>
  <div class="article-blockquote">
    <IQuote :quote="body.quote" :citation="body.citation" :animate="false" />
  </div>
</template>

<script>
import IQuote from "@/components/items/I-Quote.vue";
export default {
  components: {
    IQuote,
  },
  props: {
    body: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.article-blockquote {
  padding: 30px 0;
}
</style>
