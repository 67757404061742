<template>
  <WSection :margin="true" :class="$options.name">
    <WMaxWidth>
      <figure
        :class="['C', { addPadding: addPadding, loading: loading }]"
        v-observe-visibility="animate ? observed : () => {}"
      >
        <WAspectRatio
          v-if="
            blok.videoUrl &&
            blok.videoUrl.vimeo_data &&
            blok.videoUrl.vimeo_data.id
          "
        >
          <WVimeoSource
            v-if="isVisible && onLoading"
            ref="vimeoSource"
            :title="label"
            :vimeoId="blok.videoUrl.vimeo_data.id"
            v-on:playing="onPlaying"
            v-on:loading="onLoading"
          />
          <WVideoPoster
            :posterImage="posterImage"
            :showPoster="!playing"
            :loading="loading"
            v-on:play="onPlay"
            :sizes="posterSizes"
          />
        </WAspectRatio>
        <figcaption v-if="blok.label">
          {{ blok.label }}
        </figcaption>
      </figure>
    </WMaxWidth>
  </WSection>
</template>

<script>
import WSection from "@/components/wrappers/W-Section.vue";
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WVideoPoster from "@/components/wrappers/W-VideoPoster.vue";
import WVimeoSource from "@/components/wrappers/W-VimeoSource.vue";
import WAspectRatio from "@/components/wrappers/W-AspectRatio.vue";
import IText from "@/components/items/I-Text.vue";
export default {
  name: "CompVideo",
  components: {
    WSection,
    WMaxWidth,
    WVideoPoster,
    WVimeoSource,
    WAspectRatio,
    IText,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      playing: false,
      loading: false,
      isVisible: false,
    };
  },
  computed: {
    label() {
      return this.blok.label;
    },
    posterImage() {
      return this.blok.videoPosterImage;
    },
    addPadding() {
      return this.blok.settings.includes("addPadding");
    },
    posterSizes() {
      return `
          (min-width: ${this.$breakpoints.desktopLarge}px) ${
        this.$breakpoints.desktopLarge * 0.09
      }rem,
          95vw
        `;
    },
  },
  watch: {},
  mounted() {
    //console.log(this.component)
    if (!this.animate) this.isVisible = true;
  },
  activated() {
    // Only used if keep-alive is used
  },
  deactivated() {
    // Only used if keep-alive is used
  },
  methods: {
    onPlaying(val) {
      this.playing = val;
    },
    onLoading(val) {
      this.loading = val;
    },
    onPlay() {
      this.$refs.vimeoSource.showLoader();
      this.$refs.vimeoSource.play();
    },
    observed(isVisible, entry) {
      this.animateIn(isVisible, entry);
      if (isVisible) {
        this.isVisible = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.C {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @extend %grid-column-gap;

  > div {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  figcaption {
    grid-column-start: 2;
    grid-column-end: 12;
    @media (min-width: $break-mobile-landscape) {
      grid-column-start: 3;
      grid-column-end: 11;
    }
    @media (min-width: $break-tablet-portrait) {
      grid-column-start: 4;
      grid-column-end: 10;
    }
    text-align: center;
  }
  @media (min-width: $break-tablet-landscape) {
    &.addPadding {
      > div {
        grid-column-start: 2;
        grid-column-end: 12;
      }
    }
  }
}
</style>
