<template>
  <div class="grid">
    <div class="grid__item" v-for="item in body.listItems" :key="item.id">
      <RPersonArticle
        :blok="item"
        :listHasImages="listHasImages"
        :placeholderImage="placeholderImageUrl"
      ></RPersonArticle>
    </div>
  </div>
</template>

<script>
import RPersonArticle from "@/components/references/R-PersonArticle.vue";
export default {
  name: "articleList",
  components: {
    RPersonArticle
  },
  props: {
    body: {
      type: Object,
      require: true
    }
  },
  computed: {
    listHasImages() {
      // Check if any of the list items has an image
      return this.body.listItems.some(item => item.image);
    },
    placeholderImageUrl() {
      // Placeholder image for persons without an image
      return this.body.placeholderImg?.filename || "";
    }
  }
};
</script>

<style lang="scss" scoped>
.grid {
  padding: 3rem 0 0;
  display: grid;
  grid-template-columns: 1fr;
  @extend %grid-column-gap;

  @media (min-width: $break-mobile-landscape) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: $break-mobile-portrait + ($break-mobile-landscape - $break-mobile-portrait) / 2) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: $break-tablet-landscape) {
    grid-template-columns: repeat(3, 1fr);
  }
  &__item {
    padding: 0 0 3rem;
  }
}
</style>
