<template>
  <transition :name="name" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <div v-if="expanded">
      <div :class="classes">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TransitionExpandHeight",
  props: {
    name: {
      type: String,
      default: () => {
        return "grow-up";
      },
      validator: value => {
        return ["no-transition", "grow-up", "fade-down"].indexOf(value) !== -1;
      }
    },
    expanded: {
      type: Boolean,
      required: true
    },
    classes: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    afterEnter(element) {
      element.style.height = `auto`;
    },
    enter(element) {
      const { width } = getComputedStyle(element);

      element.style.width = width;
      element.style.position = `absolute`;
      element.style.visibility = `hidden`;
      element.style.height = `auto`;

      const { height } = getComputedStyle(element);

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    leave(element) {
      const { height } = getComputedStyle(element);

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    }
  }
};
</script>

<style scoped>
* {
  will-change: height, opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<style lang="scss">
.grow-up-enter-active,
.grow-up-leave-active {
  transition: all 350ms cubic-bezier($ease-in-out-cubic);
  overflow: hidden;
  > * {
    transition: all 350ms cubic-bezier($ease-in-out-cubic);
  }
}
.grow-up-enter,
.grow-up-leave-to {
  height: 0;
  > * {
    transform: translateY(1rem);
    opacity: 0;
  }
}

.fade-down-enter-active {
  transition: all 500ms cubic-bezier($ease-in-out-cubic) 0ms;
  overflow: hidden;
  @media (min-width: $break-terra-x-nav) {
    transition: all 500ms cubic-bezier($ease-in-out-cubic) 250ms;
  }
  > * {
    transition: all 200ms 150ms cubic-bezier($ease-in-out-cubic);
  }
}
.fade-down-leave-active {
  transition: all 200ms 150ms cubic-bezier($ease-in-out-cubic);
  overflow: hidden;
  > * {
    transition: all 350ms cubic-bezier($ease-in-out-cubic);
  }
}
.fade-down-enter,
.fade-down-leave-to {
  height: 0;
  opacity: 0;
  > * {
    transform: translateY(-1rem);
    opacity: 0;
  }
}
</style>