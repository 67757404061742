<template>
  <div
    :class="[
      $options.name,
      'quote',
      { 'quote--inverted': inverted, 'quote--font-b1': fontB1 },
    ]"
  >
    <p
      class="quote__lead"
      v-if="label"
      v-observe-visibility="animate ? observed : () => {}"
    >
      {{ label }}
    </p>
    <h2 v-if="title" v-observe-visibility="animate ? observed : () => {}">
      {{ title }}
    </h2>
    <blockquote
      :class="quoteSize"
      v-observe-visibility="animate ? observed : () => {}"
    >
      <richtext :text="quote"></richtext>
    </blockquote>
    <cite
      class="quote__citation"
      v-if="citation"
      v-observe-visibility="animate ? observed : () => {}"
    >
      {{ citation }}
    </cite>
  </div>
</template>

<script>
import richtext from "@/components/utils/richtext.vue";
export default {
  name: "I-Quote",
  components: {
    richtext,
  },
  props: {
    label: {
      type: String,
    },
    title: {
      type: String,
    },
    quoteSize: {
      type: String,
      default: "large",
    },
    quote: {
      type: Object,
    },
    citation: {
      type: String,
    },
    inverted: {
      type: Boolean,
    },
    fontB1: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: Boolean,
      default: true,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAnimationFor: {
        a: false,
        b: false,
        c: false,
      },
    };
  },
  methods: {
    isViewableNow(isVisible, entry, section) {
      this.showAnimationFor[section] = isVisible;
    },
    observed(isVisible, entry) {
      if (this.transition) {
        this.animateIn(isVisible, entry);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.quote {
  &__lead {
    @extend %lead;
  }
  &__citation {
    margin-top: 2rem;
    display: block;
    width: 100%;
    padding-left: 2em;
    text-align: right;
    &:before {
      content: "–";
      display: inline-block;
      padding-right: 0.6em;
    }
  }
  &--inverted /deep/ {
    color: white;
  }
  &--font-b1 {
    > div /deep/ {
      p {
        @extend %body1;
      }
    }
  }
}
</style>
