<template>
  <div class="richtext">
    <rich-text-renderer v-if="!htmltext" :document="text" />
    <div v-html="htmltext" v-else></div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: [String, Object],
      default: "",
    },
  },
  computed: {
    htmltext() {
      return typeof this.text === "string" ? this.text : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.richtext {
  @extend %body2;
}

// styling of elements inside rich text
.richtext /deep/ {
  > * {
    width: 100%;
  }
  hr {
    @extend %hr;
  }
  ol {
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-left: 6.6rem;
    & li {
      padding-left: 1rem;
      margin: 0.75em 0;
      & p {
        margin: 0.75em 0;
      }
      &::marker {
        font-weight: bold;
      }
    }
  }
  ul {
    position: relative;
    padding-left: 3.5rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    p {
      margin: 0.75em 0;
    }
    li {
      padding-left: 4.1rem;
      &:before {
        content: "";
        display: block;
        border-color: currentColor;
        border-style: solid;
        border-width: 0;
        border-bottom-width: 0.2rem;
        width: 2.5rem;
        height: 0;
        position: absolute;
        transform: translate(-4rem, 0.7em);
      }
    }
    ul {
      transform: translateX(-2.5rem);
      margin-top: 20px;
      margin-bottom: 30px;
      li {
        &:before {
          border-left-width: 0.2rem;
          width: 1.5rem;
          height: 0.7rem;
          transform: translate(-3rem, 0.5em);
        }
      }
    }
  }
  figure {
    figcaption {
      text-align: center;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  a {
    position: relative;
    color: currentColor;
    font-weight: 900;
    align-items: center;
    svg {
      position: relative;
      bottom: -5px;
      transform: scale(0.8) translate(-5%, 0);
      margin-left: -0.15em;
      margin-right: 0.15em;
      transition: transform 0.3s cubic-bezier($ease-out-cubic);
      //fill: $color-blue;
    }
    &:after {
      content: "";
      display: inline-block;
      position: relative;
    }
    @media (hover: hover) {
      &:not(.asset-hyperlink) {
        &:hover {
          svg {
            transform: scale(0.8) translate(25%, 0);
          }
        }
      }
      &.asset-hyperlink {
        &:hover {
          svg {
            transform: scale(0.8) translate(-5%, 25%);
          }
        }
      }
    }
  }
  p {
    &:empty {
      display: none;
    }
  }

  & .caption {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.05em;
    opacity: 0.5;
  }

  & .body_small {
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 1.6em;
    margin-top: -1rem;
    display: block;
  }
}

// [spacing]
.richtext /deep/ {
  & p {
    // [paragraph]
    & + p {
      margin-top: 2rem;
    }
    // [figure]
    & + figure,
    & + ul,
    & + blockquote {
      margin-top: 5rem;
    }
    // [header]
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5 {
      margin-top: 4rem;
    }
    // [blockquote]
    & + blockquote {
      margin-top: 4rem;
    }
  }
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    // [paragraph]
    & + p,
    & + ul {
      margin-top: 2rem;
    }
    // [figure]
    & + figure {
      margin-top: 5rem;
    }
    // [header]
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5 {
      margin-top: 0rem;
    }
    // [blockquote]
    & + blockquote {
      margin-top: 4rem;
    }
  }
  > ul {
    // [figure]
    & + p,
    & + figure,
    & + ul {
      margin-top: 4rem;
    }
    // [header]
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5 {
      margin-top: 4rem;
    }
    // [blockquote]
    & + blockquote {
      margin-top: 4rem;
    }
  }
  > figure {
    // [paragraph]
    & + p {
      margin-top: 5rem;
    }
    // [figure]
    & + figure,
    & + ul,
    & + blockquote {
      margin-top: 5rem;
    }
    // [header]
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5 {
      margin-top: 5rem;
    }
    // [blockquote]
    & + blockquote {
      margin-top: 5rem;
    }
  }
}
</style>
