<template>
  <nav
    :class="[
      'nav',
      $options.name,
      { menuOpen: menuOpen },
      { inverted: inverted }
    ]"
  >
    <WNavTopbar
      @burger-click="burgerClick"
      @close="closeMenu"
      :menu-open="menuOpen"
      :inverted="inverted"
    />

    <transition name="fade" mode="out-in">
      <div class="overlay-darken" v-if="menuOpen" @click="closeMenu"></div>
    </transition>

    <div
      :class="[
        'nav-sidebar',
        { 'nav-sidebar--open': menuOpen },
        parentHasLayoutAstra ? 'astra-theme' : ''
      ]"
      v-if="pageLoaded"
      ref="sidebar"
    >
      <WNavPrimary
        @close="closeMenu"
        :listen-to-folder="triggerFolderClose"
        :nav="nav"
      />
      <WNavSecondary
        @cookie-popup-open="openCookiePopup"
        @close="closeMenu"
        :footer="footer"
      />
    </div>
  </nav>
</template>

<script>
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";

import WNavTopbar from "@/components/wrappers/W-NavTopbar.vue";
import WNavPrimary from "@/components/wrappers/W-NavPrimary.vue";
import WNavSecondary from "@/components/wrappers/W-NavSecondary.vue";

export default {
  name: "W-Nav",
  components: {
    WNavTopbar,
    WNavPrimary,
    WNavSecondary
  },
  data() {
    return {
      triggerFolderClose: false,
      pageLoaded: false
    };
  },
  watch: {
    menuOpen(menuIsOpen) {
      if (menuIsOpen) {
        disableBodyScroll(this.$refs.sidebar);
      } else {
        enableBodyScroll(this.$refs.sidebar);
      }
    }
  },
  props: {
    inverted: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Object,
      required: true
    },
    nav: {
      type: Array,
      required: true
    },
    hasLayoutAstra: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    parentHasLayoutAstra() {
      if (this.$store.state.layout === "LayoutAstra") {
        return !this.hasLayoutAstra;
      }
    },
    menuOpen() {
      return this.$store.getters["navigation/isMenuOpen"]();
    }
  },
  methods: {
    burgerClick() {
      this.menuOpen ? this.closeMenu() : this.openMenu();
    },
    closeMenu() {
      this.$store.commit("navigation/close");
      this.triggerFolderClose = true;
    },
    openMenu() {
      this.$refs.sidebar.scrollTop = 0;
      this.$store.commit("navigation/open");
      this.triggerFolderClose = false;
    },
    openCookiePopup() {
      this.$nuxt.$emit("cookie-popup-open");
    }
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  mounted() {
    this.$nextTick(() => {
      this.pageLoaded = true;
    });
  }
};
</script>

<style lang="scss" scoped>
.overlay-darken {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -2;
  background-color: $color-blue;
  opacity: 0.5;
}

.nav {
  z-index: $z-nav;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 250s;
  @media (min-width: $break-nav) {
    position: relative;
  }
}
.astra-theme {
  &.nav-sidebar {
    background-color: $color-astra-black;
  }
}
.nav-sidebar {
  position: fixed;
  width: 100%;
  max-width: $nav-sidebar-max-width;
  background-color: $color-white;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  overflow: auto;
  padding-top: $nav-height;
  transition: transform $nav-sidebar-transition-speed
    $nav-sidebar-transition-easing;
  transform: translateX(100%);
  $i: 1;
  @include poly-fluid-sizing(
    padding-left,
    (
      $break-mobile-portrait: $padding-mobile * $i,
      $break-desktop-large: $padding-desktop * $i
    )
  );
  @include poly-fluid-sizing(
    padding-right,
    (
      $break-mobile-portrait: $padding-mobile * $i,
      $break-desktop-large: $padding-desktop * $i
    )
  );
  @extend %padding-bottom-x1;
  &--open {
    transform: translateX(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $nav-sidebar-transition-speed;
}
.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
}

.ie-message {
  display: none;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
    background-color: white;
    position: relative;
    width: 100%;
    left: 0;
    text-align: center;
    padding: 0rem 4rem 2rem 4rem;
    font-size: 1.5rem;
    &:before {
      display: block;
      content: "Some features are not available in Internet Explorer.";
      //content: "The page is not optimized for Internet Explorer.";
    }
    &:after {
      display: block;
      padding-top: 0.5rem;
      content: "We recommend using a more modern browser or a different device for the full experience.";
      //content: "If you experience issues, we recommend using another modern browser or try a different device.";
    }
  }
}
</style>
