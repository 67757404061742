import Fuse from 'fuse.js'

// GLOBAL FUNCTIONS
// remove duplicate values in array
const _uniq = arr => [...new Set(arr)]
// remove "", null, false, undefined
const _compact = arr => arr.filter(el => (el && el != ''))
// sort alphabetically
const _sort = arr => arr.sort()

export const state = () => ({
  filterIsOpen: false,
  projects: [],
  investors: [],
  insurance: [],
  activeSectors: [],
  activeRegions: [],
  routeQuery: {
    type: "projects", //need default = projects
    globe: true,
    sectors: [],
    region: undefined,
    stageOfDevelopment: undefined,
    search: undefined,
  },
  globeHide: false,
  globePosition: {},
  globeFromTop: 0,
  globeButtonDisabled: false,
  analyticsTrackSearchTimeout: null,
  selectedPin: null,
  orientationIsLandscape: true,
  selectedPinDetails: {},
})

export const getters = {
  getInvestorsCount: (state) => (props) => {
    return state.investors.length
  },
  getProjectsCount: (state) => (props) => {
    return state.projects.length
  },
  getInsuranceCount: (state) => (props) => {
    return state.insurance.length
  },
  isFilterOpen: (state) => (props) => {
    return state.filterIsOpen
  },

  getType: (state) => (props) => {
    return state.routeQuery.type
  },
  getGlobeHide: (state) => (props) => {
    return state.globeHide
  },
  isGlobeOpen: (state) => (props) => {
    return state.routeQuery.globe
  },
  isGlobeButtonDisabled: (state) => (props) => {
    return state.globeButtonDisabled
  },
  getRouteQuery: (state) => (props) => {
    return {
      type: state.routeQuery.type,
      globe: state.routeQuery.globe,
      sectors: state.routeQuery.sectors,
      region: state.routeQuery.region,
      stageOfDevelopment: state.routeQuery.stageOfDevelopment,
      search: state.routeQuery.search,
    }
  },
  getFilters: (state) => (datasourceName, datasourceType = 'main') => {
    const type = state.routeQuery.type;

    let data = state[type].map((item) => {
      // clean from sub-filters - only keep main-filters (so fx in 'africa_north-africa' we only want to keep 'africa')
      let valuesArr = !item[datasourceName] ? [] : Array.isArray(item[datasourceName]) ? item[datasourceName] : [item[datasourceName]]
      if (datasourceType === 'main') {
        valuesArr = valuesArr.map(value => {
          const mainFilter = value.split('_')[0]
          return mainFilter
        })
      }
      return valuesArr
    }).flat();
    let uniq = _uniq(data)

    let clean = uniq
    clean = _compact(uniq)
    clean = _sort(clean)

    return clean
  },
  getFiltersArrOfObjs: (state, getters, rootState) => (arr, filterName, filterType = 'main') => {
    let datasourceName = ''
    switch (filterName) {
      case 'region':
        datasourceName = 'regions'
        break
      case 'sectors':
        datasourceName = 'sectors'
        break
      case 'stageOfDevelopment':
        datasourceName = 'project-stages-of-development'
        break
    }
    const datasource = rootState.datasources[datasourceName]

    // map values to pretty datasource names
    if (datasource && Object.keys(datasource).length > 0) {
      // console.log('arr', arr, 'datasource', datasource)
      arr = arr.map(item => {
        const itemFromDatasource = datasource.entries[item]
        return {
          name: itemFromDatasource ? itemFromDatasource.name : null,
          value: item,
        }
      })
    }
    return arr
  },
  checkSectors: (state) => (props) => {
    const active = state.routeQuery.sectors;
    if (!active.length > 0) {
      return true
    } else {
      // make sure filter works even if only a sub-filter is chosen
      // only check for main sector name
      const mainSectors = props.sector.map(sect => {
        const parts = sect.split('_')
        if (parts.length) return parts[0]
        return ''
      })

      // ADDITIVE FILTERS - return true if the project contains ANY of the selected sectors-filters
      return active.some(r => mainSectors.includes(r))

      // SUBTRACTIVE FILTERS - return true if the project contains ALL of the selected sectors-filters
      // return mainSectors.join(', ').includes(active)

    }
  },
  checkRegion: (state) => (props) => {
    const active = state.routeQuery.region;
    if (!active) {
      return true
    } else {
      // make sure filter works even if only a sub-filter is chosen
      return props.region.join(', ').includes(active)
    }
  },
  checkStageOfDevelopment: (state) => (props) => {
    const active = state.routeQuery.stageOfDevelopment;
    if (!active) {
      return true
    } else {
      return props.stageOfDevelopment.includes(active)
    }
  },
  filteredData: (state, getters) => (props) => {
    const type = state.routeQuery.type;

    let data = state[type];

    // filter by active sectors and regions
    data = state[type].filter((item) => {
      return getters.checkSectors(item) && getters.checkRegion(item) && getters.checkStageOfDevelopment(item)
    })

    // fuzzy search
    if (state.routeQuery.search) {
      const options = {
        keys: [
          'title',
          'description',
          'location',
          'url',
          'sector',
          'region',
          'stageOfDevelopment',
        ]
      }
      const pattern = state.routeQuery.search
      const searchResults = new Fuse(data, options).search(pattern)
      data = searchResults.map(result => result.item)
    }

    // get Active Sectors
    let as = data.map((item) => {
      return item.sector
    }).flat();
    let asUniq = _uniq(as)

    // get Active Regions
    let ar = data.map((item) => {
      return item.region
    }).flat();
    let arUniq = _uniq(ar)

    // get Active StagesOfDevelopment
    let sod = data.map((item) => {
      return item.stageOfDevelopment
    }).flat();
    let sodUniq = _uniq(sod)

    return {
      outputLength: data.length,
      activeSectors: asUniq,
      activeRegions: arUniq,
      activeStagesOfDevelopment: sodUniq,
      output: data // we could map data & remove things the map dont need
    }
  },
  getSelectedPin: (state) => (props) => {
    return state.selectedPin
  },
  isLandscapeOrientation: (state) => (props) => {
    return state.orientationIsLandscape
  },
  getSelectedPinDetails: (state) => (props) => {
    return state.selectedPinDetails
  },
}

export const mutations = {
  setActiveSectors(state, data) {
    state.activeSectors = data;
  },
  setActiveRegions(state, data) {
    state.activeRegions = data;
  },
  setSectors(state, data) {
    state.routeQuery.sectors = data ? data.sort() : [];
  },
  setRegion(state, data) {
    state.routeQuery.region = data;
  },
  setStageOfDevelopment(state, data) {
    state.routeQuery.stageOfDevelopment = data;
  },
  setSearch(state, data) {
    if (data === '') data = undefined
    state.routeQuery.search = data;
  },
  filterToggle(state) {
    state.filterIsOpen = !state.filterIsOpen
    // deselectPin(state)
  },
  filterClose(state) {
    state.filterIsOpen = false
    //  deselectPin(state)
  },
  filterOpen(state) {
    state.filterIsOpen = true
    // deselectPin(state)
  },
  globeHideIt(state) {
    state.globeHide = true
  },
  globeShowIt(state) {
    state.globeHide = false
  },
  globeClose(state) {
    state.routeQuery.globe = false
    state.routeQuery.search = undefined;
    // deselectPin(state)
  },
  globeOpen(state) {
    state.routeQuery.globe = true
    state.routeQuery.search = undefined;
    //deselectPin(state)
  },
  disableGlobeButton(state, data) {
    state.globeButtonDisabled = data;
  },
  setTerraXProjects(state, payload) {
    state.projects = payload;
    // deselectPin(state)
  },
  setTerraXInvestors(state, payload) {
    state.investors = payload;
    //deselectPin(state)
  },
  setTerraXInsurance(state, payload) {
    state.insurance = payload;
    //deselectPin(state)
  },
  setQueryType(state, payload) {
    state.routeQuery.type = payload
    state.routeQuery.search = undefined;
  },
  setAnalyticsTrackSearchTimeout(state, payload) {
    state.analyticsTrackSearchTimeout = payload
  },
  clearAnalyticsTrackSearchTimeout(state, payload) {
    clearTimeout(state.analyticsTrackSearchTimeout)
    state.analyticsTrackSearchTimeout = null
  },
  stateFromQuery(state, payload) {
    const sectorArr = !payload.sectors ? [] : Array.isArray(payload.sectors) ? payload.sectors : [payload.sectors]

    let globe = true
    if (payload.globe === 'false') globe = false
    else if (payload.globe === 'true') globe = true
    else !state.routeQuery.globe ? false : true

    state.routeQuery = {
      type: payload.type || state.routeQuery.type,
      globe: globe,
      sectors: sectorArr || [],
      region: payload.region || undefined,
      stageOfDevelopment: payload.stageOfDevelopment || undefined,
      search: payload.search ? payload.search : state.routeQuery.search || undefined
    }
  },
  setGlobePositionLayoutFind(state, payload) {
    state.globePosition = {
      ...state.globePosition,
      ...payload
    }
    // console.log('MUTATION', 'setGlobePosition', state.globePosition)
  },
  setGlobeFromTop(state, pos) {
    state.globeFromTop = pos
  },
  setSelectedPin: (state, data) => {
    state.selectedPin = data
  },
  landscapeOrient: (state, data) => {
    state.orientationIsLandscape = data
  },
  setSelectedPinDetails: (state, data) => {
    state.selectedPinDetails = data
  },
}

const deselectPin = (state) => {
  state.selectedPin = null
  state.selectedPinDetails = null
}

export const actions = {
  setStateFromQuery({
    commit
  }, payload) {
    commit('stateFromQuery', payload);
  },
  setUrlQuery({
    getters,
    dispatch,
  }) {
    this.$router.push({
      query: getters.getRouteQuery()
    })
    dispatch("analyticsTrackSearch")
  },
  analyticsTrackSearch({
    commit,
    getters,
  }) {
    // prevent firing the view_search_results event too often
    // we only track after 1 second without any parameters being changed
    // if we didn't use a timeout, then we would fire a event everytime people type a new letter in the free text search input
    // console.log('track - triggered - but waiting for timeout...', )
    commit('clearAnalyticsTrackSearchTimeout')
    commit('setAnalyticsTrackSearchTimeout', setTimeout(() => {
      const routeQuery = getters.getRouteQuery()
      let eventParams = {
        search_term: routeQuery.search,
        q_type: routeQuery.type,
        q_region: routeQuery.region,
        q_globe: routeQuery.globe,
        q_sectors: routeQuery.sectors.length ? routeQuery.sectors : undefined,
        q_stageOfDevelopment: routeQuery.stageOfDevelopment,
      }
      // console.log('track - view_search_results', eventParams)
      this._vm.$gtag.event('view_search_results', eventParams)
    }, 1000))
  },
  clearFilters({
    commit,
    dispatch
  }) {
    commit('setSectors', []);
    commit('setRegion', undefined);
    commit('setStageOfDevelopment', undefined);
    dispatch('setUrlQuery');
  },
  clearSearch({
    commit,
    dispatch
  }) {
    commit('setSearch', undefined);
    dispatch('setUrlQuery');
  },
}
