<template>
  <div
    :class="[
      $options.name,
      'text',
      { 'text--inverted': inverted, 'text--font-b1': fontB1 },
    ]"
  >
    <img
      class="text__icon"
      v-if="icon && icon.filename"
      :src="icon.filename"
      alt
      width="90"
      height="60"
      v-observe-visibility="observed"
    />
    <p v-if="label" class="text__lead" v-observe-visibility="observed">
      {{ label }}
    </p>
    <h2 v-if="title && !titleH3" v-observe-visibility="observed">
      {{ title }}
      <sup class="list-count" v-if="countIt">{{ countIt }}</sup>
    </h2>
    <h3 v-if="title && titleH3" v-observe-visibility="observed">
      {{ title }}
      <sup class="list-count" v-if="countIt">{{ countIt }}</sup>
    </h3>
    <richtext v-if="content" :text="content" v-observe-visibility="observed" />
    <ul
      class="text__button-list"
      v-if="buttons"
      v-observe-visibility="observed"
    >
      <li
        class="text__button-list-item"
        v-for="(button, index) in buttons"
        :key="`button-${index}`"
      >
        <IButton :button="button" :inverted="inverted"></IButton>
      </li>
    </ul>
  </div>
</template>

<script>
import IButton from "@/components/items/I-Button.vue";
import richtext from "@/components/utils/richtext.vue";
export default {
  name: "I-Text",
  components: {
    IButton,
    richtext,
  },
  props: {
    icon: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    countIt: {
      type: Number,
      required: false,
    },
    content: {
      type: [String, Object],
      required: false,
    },
    buttons: {
      type: Array,
      required: false,
    },
    inverted: {
      type: Boolean,
      required: false,
    },
    fontB1: {
      type: Boolean,
      required: false,
      default: false,
    },
    titleH3: {
      type: Boolean,
      required: false,
      default: false,
    },
    transition: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      showAnimationFor: {
        a: false,
        b: false,
        c: false,
      },
    };
  },
  methods: {
    isViewableNow(isVisible, entry, section) {
      this.showAnimationFor[section] = isVisible;
    },
    observed(isVisible, entry) {
      if (this.transition) {
        this.animateIn(isVisible, entry);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-count {
  @extend %headline5;
  display: inline-block;
}
.text {
  &__icon {
    margin-bottom: 2rem;
    width: 9rem;
    height: auto;
  }
  &__lead {
    @extend %lead;
  }
  &__button-list {
    margin-top: 2rem;
    display: flex;
    align-items: center;
  }
  &__button-list-item {
    &:not(:first-child) {
      margin-left: 3rem;
    }
  }
  &--inverted /deep/ {
    color: white;
    .text__icon {
      filter: invert(1) contrast(2);
    }
  }
  & h2 {
    margin-bottom: 15px;
  }
  &--font-b1 {
    > div /deep/ {
      p {
        @extend %body1;
      }
    }
  }
}
</style>
