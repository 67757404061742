<template>
  <transition name="modal" mode="out-in" appear duration="500">
    <div class="modal-position" :style="`${styling} --vX:${vX}; --vY:${vY};`">
      <article class="modal">
        <div class="modal__header">
          <div class="modal__sector">{{ formatArray(sector, "sectors") }}</div>
          <div class="modal__location">
            <IconMap></IconMap>
            <span v-if="location">{{ showLocation(location) }}</span>
          </div>
        </div>
        <h4>{{ title }}</h4>
        <p>{{ description }}</p>
        <nuxt-link :to="url">
          <span>Learn more</span>
        </nuxt-link>
      </article>
    </div>
  </transition>
</template>

<script>
import asideDataFormatMixin from "~/components/terra-x/projectsAndInvestors/asideDataFormatMixin";
import IconMap from "@/components/icons/IconMap.vue";
export default {
  name: "TerraXGlobeModal",
  mixins: [asideDataFormatMixin],
  components: {
    IconMap,
  },
  props: {
    containerBounding: {
      required: false,
    },
    styling: {
      type: String,
      required: false,
      default: "",
    },
    x: {
      type: String,
      required: false,
      default: "0",
    },
    y: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data() {
    return {};
  },
  computed: {
    details() {
      return this.$store.getters["terrax/getSelectedPinDetails"]();
    },
    description() {
      if (!this.details) {
        return "";
      }
      return this.details.description;
    },
    title() {
      if (!this.details) {
        return "";
      }
      return this.details.title;
    },
    location() {
      if (!this.details) {
        return "";
      }
      return this.details.location;
    },
    url() {
      if (!this.details) {
        return "";
      }
      return this.details.url;
    },
    sector() {
      if (!this.details) {
        return [];
      }
      const sectors = this.details.sector;
      if (sectors && sectors.length > 0) {
        return sectors;
      }
      return [];
    },
    xPos() {
      if (!this.containerBounding) {
        return 0;
      }
      return this.x - this.containerBounding.width / 2;
    },
    yPos() {
      if (!this.containerBounding) {
        return 0;
      }
      return this.y - this.containerBounding.height / 2;
    },
    vLength() {
      return Math.sqrt(this.xPos * this.xPos + this.yPos * this.yPos);
    },
    vX() {
      return this.xPos / this.vLength;
    },
    vY() {
      return this.yPos / this.vLength;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    showLocation(string) {
      if (string.includes(",")) {
        console.log(string);
        return string.split(",")[1];
      } else {
        console.log(string);
        return string;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-enter-active,
.modal-leave-active {
  .modal {
    transition: transform 0.5s cubic-bezier($ease-out-cubic), opacity 0.5s;
  }
}
.modal-enter {
  .modal {
    opacity: 0;
    transform: translateY(2rem);
  }
}
.modal-leave-active {
  .modal {
    opacity: 0;
    transform: translateY(-2rem);
  }
}
.modal-position {
  $relativeRadius: 0.035;
  $radius: calc(#{$relativeRadius * 4} * (100vh - #{$nav-height * 2}));

  z-index: 999;
  max-width: 100%;

  grid-column: 1 / span 4;
  grid-row: 5;
  @media (min-width: $break-terra-x-nav) and (orientation: portrait) {
    position: absolute;
    bottom: 0;
    left: 0;
    grid-column: none;
    grid-row: none;
  }
  &:not(.modal-enter-active) {
    transition: transform 0.6s cubic-bezier($ease-out-cubic);
    // Detect Safari and disable transitions
    @media not all and (min-resolution: 0.001dpcm) {
      transition: none !important;
    }
  }

  @media (orientation: landscape) {
    position: absolute;
    padding-bottom: 0;
    top: 0;
    bottom: auto;

    transform: translate3d(
      calc(var(--x) - 50% - var(--vX) * (50% + 100px)),
      calc(var(--y) - 50% - var(--vY) * (50% + 100px)),
      9999px
    );
    // z-position must be set with translate3d to move the modal in front of underlaying pins
  }
}
.modal {
  background-color: white;
  padding: 3rem 2rem;
  color: $color-blue;
  cursor: auto;
  width: 100%;
  user-select: none;
  @media (orientation: landscape) {
    width: 340px;
  }
  // div {
  //   display: flex;
  //   justify-content: space-between;
  //   opacity: 0.5;
  //   span {
  //     flex: 1;
  //     @extend %lead;
  //     &:last-child {
  //       text-align: right;
  //     }
  //   }
  // }
  &__header {
    margin-bottom: 12px;
    display: flex;
    font-size: 12px;
    text-transform: uppercase;
    align-items: center;
    justify-content: space-between;
    opacity: 0.5;
    letter-spacing: 0.1rem;
  }
  &__sector {
    max-width: 70%;
    min-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
  }
  &__location {
    display: flex;
    align-items: center;
    overflow: hidden;
    & span {
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  h4 {
    margin-bottom: 1rem;
  }
  p {
    margin-top: 0.5rem;
    @extend %body3;
  }
  a {
    margin-top: 1.7rem;
    display: flex;
    height: 3.5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-blue;
    @extend %button-icon;
    span {
      @extend %body3;
    }
    /*
    &:hover{
      background-color: $color-blue;
      span{
        color: white;
      }
    }*/
  }
}
</style>
