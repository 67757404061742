import { render, staticRenderFns } from "./W-EmailLink.vue?vue&type=template&id=46f2cfc9&scoped=true&"
import script from "./W-EmailLink.vue?vue&type=script&lang=js&"
export * from "./W-EmailLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46f2cfc9",
  null
  
)

export default component.exports