<template>
  <ul
    :class="[
      'nav-primary',
      { 'folder-open': openFolder },
      parentHasLayoutAstra ? 'astra-theme' : ''
    ]"
  >
    <li class="reTv-list-item">
      <!-- <a
        href="https://www.re-tv.org/"
        target="_blank"
        rel="noopener"
        class="nav-primary-folder"
      >
        <LogoReTv class="re-tv-logo" />
        <span class="re-tv-text">Re:TV</span>
        <div class="spacer"></div>
        <IconExternal />
      </a> -->
    </li>
    <li class="nav-primary-item" v-if="baseUrl === '/'">
      <nuxt-link to="/" class="nav-primary-link">
        <span>Home</span>
      </nuxt-link>
    </li>
    <li class="nav-primary-item" v-else>
      <nuxt-link :to="baseUrl + 'home'" class="nav-primary-link">
        <span>Home</span>
      </nuxt-link>
    </li>
    <li v-for="item in nav" :key="item.id" class="nav-primary-item">
      <w-resolve-link
        class="nav-primary-link"
        :target="setTarget"
        v-if="item.component !== 'WNavFolder'"
        :slug="item.slug"
      >
        <span>{{ item.title }}</span>
        <IconExternal class="link-external" v-if="item.slug.url" />
      </w-resolve-link>

      <button
        :class="[
          'nav-primary-folder is-link',
          { 'is-active': item._uid === openFolder }
        ]"
        v-if="
          item.slug && item.slug.cached_url && item.component === 'WNavFolder'
        "
      >
        <w-resolve-link
          :class="[
            'nav-primary-link',
            { 'is-active': item._uid === openFolder }
          ]"
          :slug="item.slug"
        >
          <span>{{ item.title }}</span>
        </w-resolve-link>
        <IconPlus
          @click.native="onFolderToggle(item._uid)"
          class="folder-icon"
          :class="{ open: item._uid === openFolder }"
        />
      </button>

      <button
        v-if="item.component === 'WNavFolder'"
        @click="onFolderToggle(item._uid)"
        :class="[
          'nav-primary-folder',
          { 'is-active': item._uid === openFolder }
        ]"
      >
        <span>{{ item.title }}</span>
        <IconPlus
          class="folder-icon"
          :class="{ open: item._uid === openFolder }"
        />
      </button>
      <TransitionExpandHeight :expanded="item._uid === openFolder">
        <ul v-if="item.component === 'WNavFolder'" class="nav-primary-sub">
          <li
            v-for="el in item.navList"
            :key="el.id"
            class="nav-primary-sub-item"
          >
            <w-resolve-link
              class="nav-primary-sub-link"
              v-if="el.component !== 'WNavFolder'"
              :target="setTarget"
              :slug="el.slug"
            >
              <span>{{ el.title }}</span>
              <IconExternal class="link-external" v-if="el.slug.url" />
            </w-resolve-link>
          </li>
        </ul>
      </TransitionExpandHeight>
    </li>
  </ul>
</template>

<script>
import WResolveLink from "@/components/wrappers/W-ResolveLink.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconExternal from "@/components/icons/IconExternal.vue";
import LogoReTv from "@/components/icons/LogoReTv.vue";
import TransitionExpandHeight from "@/components/utils/TransitionExpandHeight.vue";

export default {
  name: "W-Nav",
  components: {
    WResolveLink,
    IconPlus,
    IconExternal,
    LogoReTv,
    TransitionExpandHeight
  },
  data() {
    return {
      openFolder: ""
      // openSubFolder: "",
    };
  },
  props: {
    listenToFolder: {
      type: Boolean,
      default: false
    },
    nav: {
      type: Array,
      required: true
    },
    hasLayoutAstra: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    listenToFolder(newValue, oldValue) {
      if (newValue) {
        this.onFolderClose();
      }
    }
  },
  computed: {
    parentHasLayoutAstra() {
      if (this.$store.state.layout === "LayoutAstra") {
        return !this.hasLayoutAstra;
      }
    },
    baseUrl() {
      return this.$store.getters["pages/getBaseUrl"]();
    }
  },
  methods: {
    onFolderToggle(id) {
      // toggle sub folders
      if (this.openFolder === id) {
        this.openFolder = "";
        // this.openSubFolder = "";
      } else {
        this.openFolder = id;
      }
    },
    // onSubFolderToggle(id) {
    //   // toggle sub folders
    //   if (this.openSubFolder === id) {
    //     this.openSubFolder = "";
    //   } else {
    //     this.openSubFolder = id;
    //   }
    // },
    onFolderClose() {
      this.openFolder = "";
      // this.openSubFolder = "";
    },
    setTarget() {
      return this.slug.url ? "_blank" : "_self";
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
.link-external {
  float: right;
}
.astra-theme {
  .nav-primary {
    & a {
      & span {
        &::after {
          background-color: $color-white;
        }
      }
    }
    &-item {
      .nav-primary-link,
      .nav-primary-folder,
      .nav-primary-sub,
      .nav-primary-sub-link {
        svg line {
          stroke: $color-white;
        }
        color: $color-white;
      }
    }
  }
}
.nav-primary {
  display: flex;
  flex-direction: column;
  & a {
    & span {
      display: inline-block;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-blue;
        bottom: 0;
        transform-origin: 0 0;
        transition: transform 0.5s cubic-bezier($ease-out-cubic);
        transform: scale(0);
      }
    }
    &:hover span::after {
      transform: scale(1);
    }
    &.nuxt-link-exact-active span::after {
      transform: scale(1);
    }
  }
  &-link {
    display: block;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2.6rem;
    transition: opacity 0.3s ease;
    opacity: 1;

    .folder-open & {
      opacity: 0.5;
      &.is-active {
        opacity: 1;
      }
    }
  }
  &-folder,
  &-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    font-size: 2.6rem;
    cursor: pointer;
    transition: opacity 0.3s ease;
    opacity: 1;
    &.is-link {
      padding: 0;
      cursor: default;
    }
    .folder-open & {
      opacity: 0.5;
      &.is-link {
        opacity: 1;
      }
      &.is-active {
        opacity: 1;
      }
    }
    & .folder-icon {
      position: relative;
      & .vertical,
      & .horizontal {
        transform: rotate(0);
        transition: transform 0.35s cubic-bezier($ease-in-out-cubic);
      }
      &.open {
        .vertical {
          transform: rotate(90deg);
        }
        .horizontal {
          transform: rotate(180deg);
        }
      }
    }
  }
  & .re-tv-logo {
    width: 4rem;
    margin-right: 1.5rem;
  }
  & .re-tv-text {
    margin-right: auto;
  }
  & .reTv-list-item {
    order: 100;
    &:hover a {
      opacity: 1;
    }
  }
  & .reTv-list-item:hover ~ li a {
    opacity: 0.5;
  }
  & .reTv-list-item:hover ~ li button {
    opacity: 0.5;
    &.is-link {
      opacity: 1;
    }
  }
  &-sub {
    &-link {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 1.8rem;
      display: block;
    }
    &-folder + .nav-primary-sub {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0 30px;
      & .nav-primary-sub-link {
        padding-top: 1rem;
        padding-bottom: 0rem;
        font-weight: 100;
        font-size: 1.6rem;
        display: block;
      }
    }
    &-folder {
      &.is-active + .nav-primary-sub {
        padding: 10px 30px;
      }
    }
  }
}
</style>
