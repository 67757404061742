<template>
  <WMaxWidth class="custom-error">
    <h1>{{ errTitle }}</h1>
    <p>{{ errBody }}</p>
    <a v-if="$route.path !== undefined && $route.path !== '/'" @click="goToHome"
      >Return to home page</a
    >
  </WMaxWidth>
</template>

<script>
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
export default {
  components: {
    WMaxWidth,
  },
  props: ["error"],
  head() {
    return {
      title: "Page not found",
    };
  },
  data() {
    return {};
  },
  computed: {
    errData() {
      return (
        this.error || {
          statusCode: 404,
        }
      );
    },
    errTitle() {
      switch (this.error.statusCode) {
        case 404:
          return "Page not found";
        default:
          return "An error occurred";
      }
    },
    errBody() {
      switch (this.error.statusCode) {
        case 404:
          return "We cannot seem to find the page you were looking for. The link you followed is probably broken or the page has been removed.";
        default:
          return "Please reload or try again later.";
      }
    },
  },
  methods: {
    goToHome() {
      window.location.href = window.origin;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-error {
  @extend %padding-top-bottom-x2;
  p {
    margin-top: 2rem;
  }
  a {
    @extend %button;
    margin-top: 4rem;
  }
}
</style>
