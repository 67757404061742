<template>
  <div class="social">
    <a
      :class="['social__link', 'social__link--' + color]"
      v-if="twitter"
      :href="twitter"
      target="_blank"
      rel="noreferrer"
      title="Twitter"
      aria-label="Twitter"
    >
      <SocialTwitter />
    </a>
    <a
      :class="['social__link', 'social__link--' + color]"
      v-if="linkedin"
      :href="linkedin"
      target="_blank"
      rel="noreferrer"
      title="Linkedin"
      aria-label="Linkedin"
    >
      <SocialLinkedin />
    </a>
  </div>
</template>

<script>
import SocialTwitter from "@/components/icons/SocialTwitter.vue";
import SocialLinkedin from "@/components/icons/SocialLinkedin.vue";
export default {
  name: "W-Social",
  components: {
    SocialTwitter,
    SocialLinkedin,
  },
  props: {
    color: {
      type: String,
      default: "white",
    },
    twitter: {
      type: String,
      default: "",
    },
    linkedin: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.social {
  display: flex;
  margin-right: 0;
  @media (min-width: $break-footer) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &__link {
    &--white {
      color: #fff;
    }
    &--black {
      color: #000;
    }
    &--blue {
      color: $color-blue;
    }
  }
}
</style>
