<template>
  <nuxt-link :to="$urlResolver(slug.cached_url)" @click.native="checkCurrentRoute">
    <slot></slot>
  </nuxt-link>
</template>

<script>
var VueScrollTo = require('vue-scrollto')

export default {
  name: "storyLink",
  props: {
    slug: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      options: {
        easing: [0.45, 0.44, 0.00, 1.00],
        lazy: false,
        offset: 0,
        force: true,
        cancelable: true,
        x: false,
        y: true
      },
    }
  },
  methods: {
    checkCurrentRoute() {
      const currentRoutePath = this.$store.state.pages.currentRoutePath
      const newUrl = this.slug.cached_url || this.slug.url
      const isSamePage = currentRoutePath == newUrl || currentRoutePath == `/${newUrl}/`
      if(isSamePage) {
        // it's the same path - scroll to top
        VueScrollTo.scrollTo(window, 1000 + window.scrollY / 4, this.options)
      }
    },
  }
};
</script>

<style lang="scss" scoped></style>
