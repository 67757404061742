<template>
  <ul
    :class="['navigation-footer', parentHasLayoutAstra ? 'astra-theme' : '']"
    v-if="footer"
  >
    <li
      class="navigation-footer-item"
      v-for="item in footer.footerLinks"
      :key="item._uid"
    >
      <w-resolve-link
        @click.native="$emit('close')"
        class="navigation-footer-link"
        :slug="item.reference"
      >
        <span>{{ item.text }}</span>
      </w-resolve-link>
    </li>
    <li class="navigation-footer-item">
      <button @click.prevent="openCookiePopup" class="navigation-footer-link">
        <span>{{ footer.cookieSettings }}</span>
      </button>
    </li>
    <li class="navigation-footer-item navigation-footer-item--social">
      <WSocial
        :twitter="footer.socialTwitterUrl"
        :linkedin="footer.socialLinkedinUrl"
        color="black"
      />
    </li>
  </ul>
</template>

<script>
import WResolveLink from "@/components/wrappers/W-ResolveLink.vue";
import WSocial from "@/components/wrappers/W-Social.vue";

export default {
  name: "W-NavWithSidebar",
  components: {
    WResolveLink,
    WSocial
  },
  props: {
    footer: {
      type: Object,
      required: true
    },
    hasLayoutAstra: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    parentHasLayoutAstra() {
      if (this.$store.state.layout === "LayoutAstra") {
        return !this.hasLayoutAstra;
      }
    },
    socialTwitter() {
      return this.footer.socialTwitterUrl;
    },
    socialLinkedin() {
      return this.footer.socialLinkedinUrl;
    }
  },
  methods: {
    openCookiePopup() {
      this.$nuxt.$emit("cookie-popup-open");
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
.navigation {
  &-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8rem;
    &.astra-theme {
      .navigation-footer-link {
        color: $color-white;
      }
      .navigation-footer-item--social {
        a {
          svg {
            color: $color-white;
          }
        }
      }
    }

    &-item {
      font-size: 1.4rem;
      margin-bottom: 2rem;
      font-weight: 800;
      &:not(:last-child) {
        margin-right: 4.5rem;
      }

      &--social {
        margin-left: -1.1rem;
      }
    }
  }
}
</style>
