<template>
  <div
    ref="videoPosterWrapper"
    :class="[$options.name, { showPoster: showPoster, loading: loading }]"
    @click="videoPosterWrapperClicked"
  >
    <slot></slot>
    <button
      class="play-button"
      aria-label="Play video"
      :style="{ '--x': x + 'px', '--y': y + 'px' }"
      v-if="showPlayBtn && !iconPosRight"
    >
      <i class="icon-play-button"></i>
    </button>
    <button
      class="play-button play-button--right"
      aria-label="Play video"
      v-else-if="showPlayBtn"
    >
      <i class="icon-play-button"></i>
    </button>
    <IconLoader class="loader" />
    <div class="click-area"></div>
  </div>
</template>

<script>
import IconLoader from "@/components/icons/IconLoader.vue";
export default {
  name: "W-VideoPosterWrapper",
  components: { IconLoader },
  props: {
    iconPosRight: {
      type: Boolean,
      default: false,
      required: false,
    },
    showPoster: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    sizes: {
      type: String,
      required: false,
      default: "80vw",
    },
    showPlayBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$refs.videoPosterWrapper.addEventListener(
      "mousemove",
      this.updateMousePosition
    );
  },
  beforeDestroy() {
    this.$refs.videoPosterWrapper.removeEventListener(
      "mousemove",
      this.updateMousePosition
    );
  },
  methods: {
    updateMousePosition(e) {
      const boundingRect =
        this.$refs.videoPosterWrapper.getBoundingClientRect();
      const x = e.clientX - boundingRect.left;
      const y = e.clientY - boundingRect.top;
      this.x = ((x - boundingRect.width / 2) / boundingRect.width) * 100;
      this.y = ((y - boundingRect.height / 2) / boundingRect.height) * 100;
    },
    videoPosterWrapperClicked() {
      if (this.showPlayBtn) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-play-button {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 67px;
  height: 67px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 65 65'%3E%3Ccircle cx='32.5' cy='32.5' r='32' stroke='%23fff'/%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M32.5 56C45.479 56 56 45.479 56 32.5S45.479 9 32.5 9 9 19.521 9 32.5 19.521 56 32.5 56zm-4.25-16.139L41 32.5l-12.75-7.361V39.86z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 65px 65px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 999px;
  background-color: rgba($color-blue, 0.2);
}
.W-VideoPosterWrapper {
  position: absolute;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  top: -0.05rem;
  cursor: pointer;
  .play-button {
    width: 6.5rem;
    height: 6.5rem;
    position: absolute;
    left: 0;
    bottom: 0;
    @extend %margin-left-x1;
    @extend %margin-bottom-x1;

    pointer-events: none;
    transition: opacity 0.3s;
    &--right {
      left: auto;
      top: 20px;
      right: 20px;
      bottom: unset;
      width: 5rem;
      height: 5rem;
      & .icon-play-button {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  .loader {
    position: absolute;
    pointer-events: none;
    color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s;
    max-height: 75%;
    max-width: 75%;
  }
  &.loading {
    .loader {
      opacity: 1;
    }
    .play-button {
      opacity: 0;
    }
  }

  &:not(.showPoster) {
    pointer-events: none;
    .W-PictureSourceSet {
      opacity: 0;
    }
    .play-button {
      opacity: 0;
    }
    .click-area {
      display: none;
    }
  }

  @media (min-width: $break-mobile-landscape) {
    .play-button {
      transition: transform 0.5s cubic-bezier($ease-out-cubic), opacity 0.5s;
      transform: translate3d(0px, 0px, 0);
      left: 50%;
      top: 50%;
      bottom: auto;
      margin-left: 0;
      margin-bottom: 0;
      & .icon-play-button {
        transform: translate3d(-50%, -50%, 0);
        background-color: transparent;
      }
      &--right {
        left: auto;
        top: 20px;
        bottom: auto;
        & .icon-play-button {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        .play-button {
          transition: transform 0.7s cubic-bezier($ease-out-cubic), opacity 0.5s;
          transform: translate3d(var(--x), var(--y), 0);
        }
      }
    }
  }
}
</style>
