<template>
  <div v-if="filename" class="image" :class="[$options.name, ...classes]">
    <transition name="fade">
      <div
        class="placeholder-wrapper"
        v-if="showPlaceholder && fileExtension !== 'svg'"
      >
        <img
          class="placeholder"
          ref="placeholder"
          alt="Placeholder for loading"
          :width="ratioW"
          :height="ratioH"
          :src="transformedImage('jpeg', ratioW)"
        />
      </div>
    </transition>
    <picture v-if="fileExtension !== 'svg'">
      <source
        type="image/webp"
        :srcset="transformedImage('webp')"
        :sizes="sizes"
      />
      <source
        type="image/jpeg"
        :srcset="transformedImage('jpeg')"
        :sizes="sizes"
      />
      <img
        :class="['actual', { 'lazy-loaded': loaded || done }]"
        ref="image"
        :width="ratioW"
        :height="ratioH"
        :alt="alt || ''"
        @load="loaded = true"
        :src="transformedImage('jpeg', 600)"
        :style="inlineStyle"
        :loading="loading"
      />
    </picture>
    <img
      v-else
      :class="['actual', { 'lazy-loaded': loaded || done }]"
      ref="image"
      :width="ratioW"
      :height="ratioH"
      :alt="alt || ''"
      @load="loaded = true"
      :src="filename"
      :style="inlineStyle"
      :loading="loading"
    />
  </div>
</template>

<script>
const Crops = ["300", "600", "960", "1024", "1280", "1440", "1600", "1760"];
export default {
  name: "I-LazyImage",
  props: {
    filename: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    focus: {
      type: String,
      default: "",
    },
    loading: {
      type: String,
      default: "lazy",
    },
    sizes: {
      type: String,
    },
    ratioW: {
      type: Number,
      default: "",
    },
    ratioH: {
      type: Number,
      default: "0",
    },
    inlineStyle: {
      type: Object,
    },
    classes: [String, Object],
  },
  data() {
    return {
      currentSrc: false,
      loaded: false,
      done: false,
      isCached: false,
    };
  },
  created() {
    this.CROPS = Crops;
  },
  mounted() {
    if (!this.filename) {
      return false;
    }
    // check if image is cached
    this.$nextTick(() => {
      this.currentSrc = this.$el.querySelector("img.actual").currentSrc;
      const image = new Image();
      image.src = this.currentSrc;
      this.isCached = image.complete;
      if (this.isCached) {
        this.done = true; // for safari! @load is not called if img is already loaded
      }
    });
  },
  watch: {
    loaded(newVal) {
      if (this.$refs.placeholder) {
        const timeout = Math.round(Math.random() * (700 - 500) + 500);
        setTimeout(() => {
          this.animateOutPlaceholder(this.$refs.placeholder, 1);
        }, timeout);
      } else {
        this.done = true;
      }
    },
    done(newVal) {
      this.$emit("done");
    },
  },
  methods: {
    animateOutPlaceholder(el, i) {
      if (!el) {
        return false;
      }
      el.src = this.transformedImage("jpeg", this.ratioW);
      el.onload = () => {
        this.done = true;
      };
    },
    transformedImage(format, width) {
      const imageService = "https://img2.storyblok.com/";
      const option = format ? `/smart/filters:format(${format})` : "";
      const path = this.filename.replace("https://a.storyblok.com", "");
      if (width) {
        let height = Math.round(width * this.imageRatio);
        if (!this.ratioH) {
          height = 0;
        }
        return `${imageService}${width}x${height}${option}${path}`;
      } else {
        return this.CROPS.map((width) => {
          let height = Math.round(width * this.imageRatio);
          if (!this.ratioH) {
            height = 0;
          }
          return `${imageService}${width}x${height}${option}${path} ${width}w`;
        }).join(",");
      }
    },
  },
  computed: {
    showPlaceholder() {
      return this.loading === "lazy" && !this.isCached && !this.done;
    },
    imageRatio() {
      return this.ratioH / this.ratioW;
    },
    fileExtension() {
      const regex = /(?:\.([^.]+))?$/;
      return regex.exec(this.filename)[1];
    },
    // TODO: Get file size from 'filename'
    // fileSize() {
    //   // const regex = /(?:\.([^.]+))?$/
    //   // return regex.exec(this.filename)[1]
    //   // return [x,y]
    // },
    // TODO: Get file aspect ratio from 'filename'
    // fileRatio() {
    //   // ... todo
    // }
  },
};
</script>

<style lang="scss" scoped>
.image {
  position: relative;
  overflow: hidden;
}

.placeholder-wrapper {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(7px);
  transform: scale(1.2);
}
img {
  display: block;
  max-width: 100%;
  width: 100%;
  &.actual {
    position: relative;
    height: auto;
  }
  &.placeholder {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
  }
}
.actual {
  opacity: 0;
  transition: opacity 1s ease;
  &.lazy-loaded {
    opacity: 1;
  }
}
.h100 {
  height: 100%;
}
</style>
