export default ({ app }, inject) => {
  inject('breakpoints', {
    widescreen: 1880,
    desktopLarge: 1440,
    desktopSmall: 1280,
    tabletLandscape: 990,
    tabletPortrait: 768,
    mobileLandscape: 576,
    mobilePortrait: 375,
  })
}