import { Callout } from '~/modules/globekit.esm.js';

let id = 0
//const Callout = require('~/modules/globekit.esm.js')

/**
 * This is the definition of the callout that appears over the selected airport
 */
class POIPinCallout extends Callout {
  createElement() {
    this.x = 0
    this.y = 0
    this.s = 0
    this.id = id
    id++

    const properties = this.definition.data.properties

    let imgPath = properties.image.filename
    // Crop to 200x200 pixels
    imgPath = imgPath.replace("https://a.storyblok.com", "https://img2.storyblok.com/200x200")

    // Here is the html element that will be attached to the coord
    const div = document.createElement('div')
    div.id = properties.id
    div.className = 'pin-callout'

    div.innerHTML =
      `<div class="callout-container">` +
      `<img src="${imgPath}">` +
      // `<p class="callout-id">${properties.id}</p>` + // for development testing
      `</div>`

    this.onClick = this.onClick.bind(this)
    this.onHoverIn = this.onHoverIn.bind(this)
    this.onHoverOut = this.onHoverOut.bind(this)
    div.addEventListener('click', this.onClick)
    div.addEventListener('mouseover', this.onHoverIn)
    div.addEventListener('mouseout', this.onHoverOut)
    return div;
  }

  // This function sets offsets for the htmlElement from the lat/lon coord
  setPosition(position) {

    // Offset the scale slightly so it only scales to 0 when a bit behind the globe
    let scale = (position.world.similarityToCameraVector + 0.25) / 1.25

    if (scale <= 0.001) {
      this.element.classList.add('hidden')
    } else {

      const x = position.screen.x.toFixed(2)
      const y = position.screen.y.toFixed(2)

      // Check if positions are actual identical... A hack indication, that the experience is lagging and we shouldn't actually modify this frame
      if (this.x == x && this.y == y) {
        // console.log("Ignore frame")
        return
      }
      if (this.id == 0) {
        //console.log(performance.now() + this.x)
      }

      document.dispatchEvent(new CustomEvent('globekitFrame', { bubbles: true }))

      this.x = x
      this.y = y
      this.s = Math.round(scale * 10000)

      if (this.element.isSelected) {
        this.element.setAttribute("data-x", this.x)
        this.element.setAttribute("data-y", this.y)
      }
      this.element.classList.remove('hidden')
      this.element.style.setProperty("--x", `${this.x}px`);
      this.element.style.setProperty("--y", `${this.y}px`);
      this.element.style.setProperty("--scale", `${this.s}`);
    }

    const minRequiredScaleToBeClickable = 0.65
    if (scale < minRequiredScaleToBeClickable) {
      // pin is on the edge of the globe - it should not be clickable
      this.element.classList.add('no-pointer-events')
    } else {
      this.element.classList.remove('no-pointer-events')
    }
    if (this.element.isSelected && scale < minRequiredScaleToBeClickable) {
      this.element.dispatchEvent(new CustomEvent('deselectPin', { bubbles: true }))
    }
  }

  onClick() {
    this.element.dispatchEvent(new CustomEvent('pinClick', { bubbles: true, detail: this.definition }))
  }

  onHoverIn() {
    this.element.dispatchEvent(new CustomEvent('pinHoverIn', { bubbles: true, detail: this.definition }))
  }

  onHoverOut() {
    this.element.dispatchEvent(new CustomEvent('pinHoverOut', { bubbles: true, detail: this.definition }))
  }
}

export { POIPinCallout }
