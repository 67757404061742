let imageToBase64
if (!process.client) {
  imageToBase64 = require('image-to-base64')
}

export const state = () => ({
  blobList: []
})
export const actions = {
  async generate({commit}, path) {
    if (!process.client) {
      await imageToBase64(path) // Path to the image
        .then(
          (response) => {
            const base64 = `data:image/jpeg;base64, ${response}`
            commit('add', {
              base64: base64,
              path: path
            })
          }
        )
        .catch(
          (error) => {
            console.log(error)
          }
        )
    }
  },
}

export const mutations = {
  add(state, values) {
    const pathExist = state.blobList.filter(e => e.path == values.path).pop()
    if (!pathExist) {
      state.blobList.push({
        base64: values.base64,
        path: values.path
      })
    }
  },
}

export const getters = {
  getBlob: (state) => (path) => {
    const result = state.blobList.filter(e => e.path == path).pop()
    return result
  }
}