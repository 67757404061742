<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line class="vertical" x1="12.1818" y1="3" x2="12.1818" y2="21" stroke="#031F35" stroke-width="2"/>
    <line class="horizontal" x1="3" y1="11.8182" x2="21" y2="11.8182" stroke="#031F35" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: "IconExternal",
  components: {},
  props: {
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
svg{
  fill: currentColor;
  line {
    transform-origin: center;
  }
}
</style>