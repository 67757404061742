var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"terra-x-globe-position-outer"},[_c('div',{staticClass:"terra-x-globe-position",class:{
      'filters-open': _vm.isFilterOpen,
      'filters-closed': !_vm.isFilterOpen,
      about: _vm.isAboutPage,
      initial: _vm.initial,
    },style:({
      '--offset-left-outer-filters-closed': _vm.offsetLeftOuterFiltersClosed,
      '--offset-left-outer-filters-open': _vm.offsetLeftOuterFiltersOpen,
      '--offset-left-outer-about': _vm.offsetLeftOuterAbout,
      '--offset-top-filters-open-mobile': _vm.offsetTopFiltersOpenMobileMobile,
    })},[_c('div',{staticClass:"terra-x-globe-position__wrapper"},[_c('TerraXNoResultsGlobe'),_vm._v(" "),_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }