<template>
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :clip-path="`url(#linkedin_${_uid})`">
      <path
        d="M28.6315 28.6345H26.262V24.922C26.262 24.0365 26.244 22.8975 25.0275 22.8975C23.792 22.8975 23.6035 23.861 23.6035 24.857V28.635H21.234V21.0005H23.51V22.041H23.5405C23.8585 21.441 24.632 20.8075 25.787 20.8075C28.1875 20.8075 28.6315 22.3875 28.6315 24.444V28.6345ZM18.558 19.9555C17.7955 19.9555 17.1825 19.338 17.1825 18.579C17.1825 17.8205 17.796 17.2035 18.558 17.2035C19.318 17.2035 19.934 17.82 19.934 18.579C19.934 19.3385 19.3175 19.9555 18.558 19.9555V19.9555ZM19.746 28.6345H17.37V21H19.746V28.6345ZM29.8165 15H16.1805C15.528 15 15 15.516 15 16.1525V29.847C15 30.4845 15.528 30.9995 16.1805 30.9995H29.8145C30.4665 30.9995 31 30.484 31 29.847V16.1525C31 15.516 30.4665 15 29.8145 15H29.8165Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath :id="`linkedin_${_uid}`">
        <rect
          width="16"
          height="16"
          fill="currentColor"
          transform="translate(15 15)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SocialLinkedin",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: currentColor;
}
</style>