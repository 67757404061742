<template>
  <i :class="['icon-arrow-left-long', color]"></i>
</template>

<script>
export default {
  name: "IconArrowLeftLong",
  props: {
    color: {
      type: String,
      default: "white"
    }
  }
};
</script>
<style lang="scss" scoped>
.icon-arrow-left-long {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M18 8l-1.4 1.4 1.6 1.6H2v2h16.2l-1.6 1.6L18 16l4-4-4-4z'/%3E%3C/svg%3E");
  &.black {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 24 24'%3E%3Cpath d='M18 8l-1.4 1.4 1.6 1.6H2v2h16.2l-1.6 1.6L18 16l4-4-4-4z'/%3E%3C/svg%3E");
  }
  &.blue {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23031f35' viewBox='0 0 24 24'%3E%3Cpath d='M18 8l-1.4 1.4 1.6 1.6H2v2h16.2l-1.6 1.6L18 16l4-4-4-4z'/%3E%3C/svg%3E");
  }
}
</style>
