export default async ({
  store,
  app
}) => {
  app.router.afterEach((to, from) => {
    const currentRoutePath = store.getters['pages/getCurrentRoutePath']()
    // save current route path on first mount of entire website
    if (!currentRoutePath) {
      store.commit('pages/setCurrentRoutePath', to.path)
    }
  })
  app.router.beforeEach((to, from, next) => {
    // close navigation
    store.commit("navigation/close");
    // check if we have a 'from' url
    if (from.name) {
      store.commit("pages/setHasBackUrl", true);
    }

    next();
  })
}
