<template>
  <div class="terra-x-globe-placeholders">
    <div class="terra-x-globe-placeholders__about">
      <TerraXGlobePlaceholder name="about" class="placeholder--about" />
    </div>
    <div class="terra-x-globe-placeholders__find">
      <TerraXGlobePlaceholder
        name="filters-open"
        class="placeholder--filters-open"
      />
      <TerraXGlobePlaceholder
        name="filters-closed"
        class="placeholder--filters-closed"
      />
    </div>
  </div>
</template>

<script>
import TerraXGlobePlaceholder from "@/components/terra-x/TerraXGlobePlaceholder.vue";
export default {
  name: "TerraXGlobePlaceholders",
  components: {
    TerraXGlobePlaceholder
  }
};
</script>

<style lang="scss" scoped>
.terra-x-globe-placeholders {
  &__about {
    display: grid;
    grid-template-areas: "globe globe . txt txt txt txt txt txt txt txt txt";
    @media (min-width: $break-nav) {
      grid-template-areas: " globe globe globe globe globe  .  txt txt txt txt txt  . ";
    }
    // background-color: yellow; // for dev
    .placeholder--about {
      grid-area: globe;
      grid-row: 1;
      // background-color: green; // for dev
    }
  }
  &__find {
    display: grid;
    grid-template-columns: 5rem 30% 1fr auto;
    // background-color: blue; // for dev

    .placeholder--filters-open,
    .placeholder--filters-closed {
      grid-row: 3;
      @media (min-width: $break-terra-x-nav) {
        grid-row: 2 / span 1;
        grid-row: 2;
      }
    }
    .placeholder--filters-open {
      // background-color: red; // for dev
      grid-column: 2 / span 3;
      grid-row: 1;
      @media (min-width: $break-terra-x-nav) {
        grid-column: 3 / span 2;
      }
    }
    .placeholder--filters-closed {
      grid-row: 2;
      grid-column: 2 / span 3;
      // background-color: purple; // for dev
      @media (min-width: $break-terra-x-nav) {
        grid-column: 1 / span 4;
      }
    }
  }
}
</style>
