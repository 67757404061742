<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6 8.4L14.2 11H2V13H14.2L11.6 15.6L13 17L18 12L13 7L11.6 8.4Z"
    />
    <path d="M22 6H20V18H22V6Z"/>
  </svg>
</template>

<script>
export default {
  name: "IconExternal",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
svg{
  fill: currentColor;
}
</style>