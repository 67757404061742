import Vue from 'vue'
Vue.mixin({
  methods: {
    animateIn: function (isVisible, entry, speed = 1500) {
      // console.log('animateIn', isVisible, entry.target)
      //entry.target.style.setProperty('--animateIn-speed', `${speed / 1000}s`)

      if (!entry.target.classList.contains('animateInit')) {
        setTimeout(() => {
          entry.target.classList.add('animateInit')
        }, 0)
        if (!isVisible) {
          setTimeout(() => {
            entry.target.classList.add('animateOut')
          }, 0)
        }
      }
      else if (isVisible) {
        entry.target.classList.remove('animateOut')
      }
    },
    observed(isVisible, entry) {
      // console.log('observed', isVisible, entry.target)
      this.animateIn(isVisible, entry);
    }
  },
})
