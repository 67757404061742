export const state = () => ({
  sealPositions: {
    'intro-left': undefined,
    'intro-center': undefined,
    'intro-width': undefined,
    'intro-width-px': undefined,
    'login-left': undefined,
    'login-center': undefined,
    'login-width': undefined,
    'login-width-px': undefined,
    'footer-left': undefined,
    'footer-center': undefined,
    'footer-width': undefined,
    'footer-width-px': undefined,
  }
})

export const getters = {
}

export const mutations = {
  setSealPositions(state, payload) {
    state.sealPositions = {
      ...state.sealPositions,
      ...payload
    }
    // console.log('MUTATION', 'setSealPositions', state.sealPositions)
  },
}

export const actions = {

}
