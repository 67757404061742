<template>
  <a :href="slug.url" :target="slug.target" rel="noopener">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "WUrlLink",
  props: {
    slug: {
      type: Object,
      require: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
