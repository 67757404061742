<template>
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32 17.7757C31.3287 18.0815 30.6133 18.2825 29.8795 18.3716C30.6424 17.9036 31.2277 17.1614 31.5034 16.2773C30.7892 16.7107 30 17.0259 29.1569 17.195C28.4849 16.4593 27.5246 16 26.4617 16C24.4237 16 22.7702 17.6956 22.7702 19.7866C22.7702 20.0834 22.8019 20.3716 22.8653 20.649C19.7961 20.4908 17.0755 18.9848 15.253 16.6912C14.935 17.2524 14.7533 17.9036 14.7533 18.597C14.7533 19.9101 15.4052 21.0693 16.3962 21.7486C15.7918 21.7302 15.2214 21.558 14.7226 21.2762V21.3229C14.7226 23.1582 15.9957 24.689 17.6862 25.0358C17.3766 25.1246 17.0502 25.169 16.7131 25.169C16.4754 25.169 16.243 25.1463 16.018 25.1018C16.4881 26.6057 17.851 27.701 19.4675 27.7302C18.2039 28.7465 16.6107 29.3521 14.8812 29.3521C14.5832 29.3521 14.2884 29.3348 14 29.3001C15.6345 30.3727 17.5764 31 19.6608 31C26.4543 31 30.168 25.2308 30.168 20.2264C30.168 20.0628 30.1649 19.8991 30.1585 19.7356C30.8801 19.2015 31.5066 18.5352 32 17.7756V17.7757Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SocialTwitter",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  fill: currentColor;
}
</style>