<template>
  <WVideoPosterWrapper
    :iconPosRight="iconPosRight"
    :showPoster="showPoster"
    :loading="loading"
    :sizes="sizes"
    @click="$emit('play')"
  >
    <ILazyImage
      class="poster"
      v-if="posterImage.filename && showPoster"
      :filename="posterImage.filename"
      :alt="posterImage.alt"
      sizes="(min-width: 600px) 80vw, 100vw"
      :ratioW="16"
      :ratioH="0"
      loading="eager"
    />
  </WVideoPosterWrapper>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";
import WVideoPosterWrapper from "@/components/wrappers/W-VideoPosterWrapper.vue";
export default {
  name: "W-VideoPoster",
  components: {
    ILazyImage,
    WVideoPosterWrapper,
  },
  props: {
    iconPosRight: {
      type: Boolean,
      default: false,
      required: false,
    },
    showPoster: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    posterImage: {
      type: Object,
      required: true,
    },
    sizes: {
      type: String,
      required: false,
      default: "80vw",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.poster {
  position: absolute !important;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  transition: opacity 0.5s;
  height: calc(56.6vw - 20px) !important;
  @media (min-width: $break-mobile-landscape) {
    height: 100% !important;
  }

  & /deep/ img {
    height: 100% !important;
    object-fit: cover;
    object-position: 50% 25%;
  }
}
</style>
