<template>
  <footer
    :class="['W-Footer footer', { 'footer--inverted': inverted }]"
    v-if="footer"
  >
    <WMaxWidth>
      <!-- <WFooterEarth :footer="footer" /> -->
      <div class="footer__wrap">
        <WFooterNav
          :footer-links="footer.footerLinks"
          :cookie-settings="footer.cookieSettings"
          :inverted="inverted"
        />
        <WSocial
          :twitter="footer.socialTwitterUrl"
          :linkedin="footer.socialLinkedinUrl"
          :color="inverted ? 'blue' : 'white'"
        />
      </div>

      <WFooterCopyright :txt="footer.footerCopyright" class="copyrights" />
    </WMaxWidth>
  </footer>
</template>

<script>
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
import WFooterNav from "@/components/wrappers/W-FooterNav.vue";
import WSocial from "@/components/wrappers/W-Social.vue";
import WFooterCopyright from "@/components/wrappers/W-FooterCopyright.vue";

export default {
  name: "WFooter",
  components: {
    WMaxWidth,
    WFooterNav,
    WSocial,
    WFooterCopyright,
  },
  props: {
    footer: {
      type: [Object, Boolean, String],
      required: true,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  background-color: $color-blue;
  color: $color-white;
  z-index: 2; // move in front of terra x globe on square-ish screens
  &--inverted {
    background-color: $color-white;
    color: $color-blue;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @extend %margin-top-bottom-x1;
    @media (min-width: $break-footer) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
</style>
