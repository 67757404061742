<template>
  <div :class="[$options.name, { bgWhite: bgWhite }]">
    <div :class="!noPadding && 'addPadding'">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "W-MaxWidth",
  components: {},
  props: {
    bgWhite: {
      type: Boolean,
      required: false
    },
    noPadding: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.W-MaxWidth {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &.bgWhite {
    background-color: white;
  }
  > div {
    max-width: $break-widescreen;
    position: relative;
    width: 100%;
  }
}
.addPadding {
  @extend %padding-left-right-x1;
}
</style>
