<template>
  <svg fill="none" viewBox="0 0 16 16" width="14" height="14">
    <g stroke="#031F35" stroke-linecap="round" stroke-linejoin="round">
      <path d="M14 6.667c0 4.667-6 8.667-6 8.667s-6-4-6-8.667a6 6 0 1112 0z" />
      <path d="M8 8.667a2 2 0 100-4 2 2 0 000 4z" />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
