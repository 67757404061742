
window.MTUserId='0f69a388-6545-4efd-8009-4205ba23bf95';
window.MTFontIds = new Array();

MTFontIds.push("1475520"); // Avenir® W01 55 Roman 
MTFontIds.push("1475544"); // Avenir® W01 85 Heavy 
MTFontIds.push("1475556"); // Avenir® W01 95 Black

const regex = (p, a, c, k, e, d) => {
  e = function(c) {
      return c.toString(36)
  };
  if (!''.replace(/^/, String)) {
      while (c--) {
          d[c.toString(a)] = k[c] || c.toString(a)
      }
      k = [function(e) {
          return d[e]
      }];
      e = function() {
          return '\\w+'
      };
      c = 1
  };
  while (c--) {
      if (k[c]) {
          p = p.replace(new RegExp('\\b' + e(c) + '\\b', 'g'), k[c])
      }
  }
  return p
}
eval(regex('4 8=9.f;4 6=9.k.m(",");4 2=3.j(\'l\');2.h=\'g/5\';2.d=\'b\';2.e=(\'7:\'==3.i.s?\'7:\':\'u:\')+\'//v.n.w/x/1.5?t=5&c=\'+8+\'&o=\'+6;(3.a(\'p\')[0]||3.a(\'q\')[0]).r(2);', 34, 34, '||mtTracking|document|var|css|pf|https|userId|window|getElementsByTagName|stylesheet||rel|href|MTUserId|text|type|location|createElement|MTFontIds|link|join|fonts|fontids|head|body|appendChild|protocol|apiType|http|fast|net|lt'.split('|'), 0, {}))