<template>
  <nav :class="['footer-nav']">
    <ul class="footer-nav__list">
      <li v-for="link in footerLinks" :key="link.id" class="footer-nav__item">
        <w-resolve-link :slug="link.reference" class="footer-nav__link">
          <span class="footer-nav__txt">{{ link.text }}</span>
          <IconArrowLeftLong :class="['icon', inverted ? 'blue' : 'white']" />
        </w-resolve-link>
      </li>
      <li class="footer-nav__item">
        <button
          @click="$nuxt.$emit('cookie-popup-open')"
          class="footer-nav__link"
        >
          <span class="footer-nav__txt">{{ cookieSettings }}</span>
          <IconArrowLeftLong :class="['icon', inverted ? 'blue' : 'white']" />
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import IconArrowLeftLong from "@/components/icons/IconArrowLeftLong.vue";
import WResolveLink from "@/components/wrappers/W-ResolveLink.vue";
export default {
  name: "WFooter",
  components: {
    IconArrowLeftLong,
    WResolveLink
  },
  props: {
    footerLinks: {
      type: Array,
      required: true
    },
    cookieSettings: {
      type: String,
      default: ""
    },
    inverted: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openCookiePopup() {
      this.$nuxt.$emit("cookie-popup-open");
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-nav {
  color: currentColor;
  display: flex;
  flex-direction: column;
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $break-footer) {
      flex: 1;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      align-items: flex-start;
      margin-right: 3.5rem;
    }
  }
  &__item {
    padding: 1rem 0;
    margin-right: 3.5rem;
    flex: 0;
    @media (min-width: $break-footer) {
      &:not(:first-child) {
        margin-left: 5rem;
      }
      &:not(:last-child) {
        margin-right: 5rem;
      }
    }
  }
  &__link {
    position: relative;
    @extend %bodyFooter;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    font-size: $font-h4;
    cursor: pointer;
    color: currentColor;
    & .icon {
      position: absolute;
      left: 100%;
      margin-left: 1em;
      transition: transform 0.5s cubic-bezier($ease-out-cubic);
      pointer-events: none;
    }
    @media (hover: hover) {
      &:hover {
        & .icon {
          transform: translateX(0.5em);
        }
      }
    }
  }
  &__text {
    margin-right: 5px;
  }
}
</style>
