// Mixin for data formatting in the LayoutProject and LayoutInvestor asides

export default {
  methods: {
    formatCurrency(number) {
      const format = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'USD',
        // currencyDisplay: 'narrowSymbol', // doesn't work in safari (except very latest version), so I don't think we should use it
        notation: "compact",
        minimumFractionDigits: '2'
      }).format(number)

      if (number) return format.replace('US$', 'US$ ')
      else return null
    },
    formatYear(dateString) {
      const validDateString = dateString.replace(/-/g, "/")
      const format = new Date(validDateString).getFullYear()
      if (validDateString) return format
      else return null
    },
    /**
     * @param {string} datasourceType - Datasource type can be 'main' or 'sub'
     */
    formatArray(array, datasourceName, datasourceType = 'main') {
      const _compact = arr => arr.filter(el => (el && el != ''))
      array = _compact(array || [])

      
      // get pretty entry name
      const datasource = this.$store.state.datasources[datasourceName]
      if (datasource && Object.keys(datasource).length > 0) {
      
        // add main-filters if they haven't been added
        array.forEach(item => {
          const val = item.split('_')[0]
          if (!(array.indexOf(val) !== -1)) array.push(val)
        })

        array.sort()
        
        // get pretty entry name
        array = array.map(item => {
          const itemFromDatasource = datasource.entries[item]
          
          if (!itemFromDatasource) return null
          
          const name = itemFromDatasource.name
          const value = itemFromDatasource.value

          // remove ('_ ' from the name of sub-filters)
          if (name.startsWith('_ ')) {
            return {...itemFromDatasource, type: 'sub', name: name.replace('_ ', ''), mainItem: value.split('_')[0]}
          } else {
            return {...itemFromDatasource, type: 'main'}
          }
        })

        const mainFilters = array.filter(item => item.type === 'main');
        const subFilters = array.filter(item => item.type === 'sub');

        const filtersToPrint = datasourceType === 'main' ? mainFilters : subFilters


        // group based on type (main or sub)
        // const arrGrouped = array.reduce((arr, el) => {
        //   if (el.type === 'main') {
        //     arr.push({...el, subs: []})
        //   } else {
        //     arr[arr.length - 1].subs.push({...el})
        //   }
        //   return arr
        // }, [])
      
        // const format = arrGrouped.map(main => {
        //   let string = main.name
        //   if (main.subs.length) {
        //     string += ' ('
        //     string += main.subs.map(sub => sub.name).join(', ')
        //     string += ')'
        //   }
        //   return string
        // }).join(', ')


        const format = filtersToPrint.map(filter => filter.name).join(', ')
        return format
      } else {
        if (array && array.length) return array.join(', ')
        return null
      }
    },
    formatString(string) {
      if (string) return string
      else return null
    },
    formatSdg(sdgArr) {
      return sdgArr.sort()
    }
  }
}
