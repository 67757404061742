<template>
  <div :class="$options.name">
    <iframe
      v-if="hasPlayer"
      ref="iframe"
      :title="title"
      allow="autoplay"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      :src="
        `https://player.vimeo.com/video/${vimeoId}?controls=${controls}&title=0&dnt=1&amp;byline=0&amp;portrait=0&amp;playsinline=1&autoplay=${autoplay}&muted=${muted}${
          loop ? '&loop=1' : ''
        }`
      "
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "W-VimeoSource",
  components: {},
  props: {
    title: {
      type: String
    },
    vimeoId: {
      type: [Number, String],
      required: true
    },
    autoplay: {
      type: [Number, String],
      default: 0
    },
    popup: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Number,
      default: 1
    },
    loop: {
      type: Boolean,
      default: false
    },
    muted: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      playing: false,
      player: null,
      hasPlayer: true,
      loading: false
    };
  },
  computed: {},
  watch: {
    playing(val) {
      this.$emit("playing", val);
    },
    loading(val) {
      this.$emit("loading", val);
    },
    popup(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.playing = false;
        this.loading = false;
        if (this.player) {
          this.player.off("play", this.onPlay);
          this.player.off("pause", this.onPause);
          const el = this.$refs.iframe;
          const vidsrc = el.src;
          el.src = "";
          el.src = vidsrc;
        }
      }
    }
  },
  computed: {
    videoPopupActive() {
      return this.$store.state.videoPopup.isActive;
    }
  },
  methods: {
    showLoader() {
      this.loading = true;
    },
    play() {
      this.loading = true;
      //this.hasPlayer = true
      //Vue.nextTick().then(() => {
      this.injectVimeoScript(() => {
        if (!this.player) {
          // console.log("Create player");
          this.player = new Vimeo.Player(this.$refs.iframe);
        }
        this.player.on("play", this.onPlay);
        this.player.on("pause", this.onPause);
        this.player.play();
      });
      //})
    },
    plause() {
      if (this.player) {
        this.player.pause();
      }
    },
    onPlay() {
      this.playing = true;
      this.loading = false;
    },
    onPause() {
      this.playing = false;
      this.loading = false;
      if (this.player) {
        this.player.off("play", this.onPlay);
        this.player.off("pause", this.onPause);
      }
    },
    injectVimeoScript(callback) {
      const existingScript = document.getElementById("vimeo-api-script");

      if (!existingScript) {
        //console.log("Create Vimeo script")
        const script = document.createElement("script");
        script.src = "https://player.vimeo.com/api/player.js"; // URL for the third-party library being loaded.
        script.id = "vimeo-api-script"; // e.g., googleMaps or stripe
        document.body.appendChild(script);

        script.onload = () => {
          if (callback) callback();
        };
      }

      if (existingScript && callback) callback();
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.off("play", this.onPlay);
      this.player.off("pause", this.onPause);
    }
  }
};
</script>

<style lang="scss" scoped>
.W-VimeoSource {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
