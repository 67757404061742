module.exports = {
  checkGenerate(slug, buildTerraX) {
    // pages on these routes will NOT be generated
    if (buildTerraX === "false") {
      return (
        slug === "home" ||
        slug.includes("global/") ||
        slug.includes("/external/") ||
        slug.includes("terra-carta-x-change") ||
        slug.includes("/ceo-testimonials/")
      );
    } else {
      return (
        slug === "home" ||
        slug.includes("global/") ||
        slug.includes("/external/") ||
        slug.includes("terra-carta-x-change/i/") ||
        slug.includes("/ceo-testimonials/")
      );
    }
  },
  getVersion(context) {
    return context.query._storyblok || context.isDev ? "draft" : "published";
  },
  getSlug(context) {
    const path = context.route.path;
    return path == "/" || path == "" ? "home" : path;
  },
  buildSupporters: function(item, supporters) {
    item["supportersList"] = supporters;
    return item;
  },
  buildNews: function(item, news) {
    let output = news;
    let blok = item;

    if (blok.listItems.length > 0) {
      let selected = output.filter(news => blok.listItems.includes(news.uuid));
      let theRest = output.filter(news => !blok.listItems.includes(news.uuid));

      output = [...selected, ...theRest];
    }
    if (blok.limit) {
      output = output.slice(0, Number(blok.limit));
    }
    blok["newsList"] = output;
    return blok;
  },
  buildData: function(page, news, supporters, testimonials) {
    let self = this;
    let newPage = page;
    // Set Layout

    if (
      page.content.component === "LayoutProject" ||
      page.content.component === "LayoutInvestor" ||
      page.content.component === "LayoutInsurance"
    ) {
      page.content["Seo"] = {
        title: page.content.title,
        description: page.content.shortDescription
      };
      page.content["thumbnail"] = page.content.backgroundImage;
    }

    if (page.content.component === "LayoutTestimonials") {
      page.content.testimonials.forEach(item => {
        item["output"] = testimonials.filter(
          video => video.category === item.category
        );
      });
    }

    if (page.content.component === "LayoutNewsArchive") {
      page.content["news"] = news;
    }

    if (page.content.body) {
      newPage = new Promise(function(resolve, reject) {
        const body = page.content.body.map(item => {
          let output = {};
          if (item.component == "CompNewsArchive") {
            output = self.buildNews(item, news);
          } else if (item.component == "CompSupporters") {
            output = self.buildSupporters(item, supporters);
          } else {
            output = item;
          }
          return output;
        });
        page.content.body = body;

        resolve(page);
      });
    }
    return newPage;
  },
  getDatasources: async function(api, version) {
    // get all datasources
    let datasources = await api.$storyapi.get(`cdn/datasources`, {
      version: version
    });
    datasources = datasources.data.datasources;

    // get all entries
    const entryRequests = [];
    datasources.forEach(datasource => {
      const request = api.$storyapi.get(`cdn/datasource_entries`, {
        version: version,
        datasource: datasource.slug,
        per_page: 300
      });
      entryRequests.push(request);
    });
    let datasourceEntries = await Promise.all(entryRequests);
    datasourceEntries.map((res, index) => {
      return res.data.datasource_entries;
    });

    // add entries to datasource
    datasources = datasources.map((datasource, index) => {
      const entriesResponse = datasourceEntries[index].data.datasource_entries;
      // store entries by slug
      const entries = {};
      entriesResponse.forEach(entry => {
        entries[entry.value] = entry;
      });
      return {
        ...datasource,
        entries
      };
    });

    // store datasources by slug
    let data = {};
    datasources.forEach(datasource => {
      data[datasource.slug] = datasource;
    });
    return data;
  },
  getAllTerraX: async function(api, version, type) {
    let per_page = 10;
    let requests = [];
    let Storyblok = api.$storyapi;
    let initial = await Storyblok.client.head("cdn/stories/", {
      params: {
        per_page: 1,
        starts_with: type,
        version: version,
        page: 1,
        token: Storyblok.accessToken,
        cv: new Date()
      }
    });
    let totalPages = Math.ceil(initial.headers.total / per_page);
    for (let i = 1; i <= totalPages; i++) {
      requests.push(
        Storyblok.get("cdn/stories/", {
          per_page: per_page,
          version: version,
          starts_with: type,
          page: i,
          cv: new Date()
        })
      );
    }
    let responses = await Promise.all(requests);

    return responses
      .map(res => {
        return res.data.stories.map(item => {
          let searchText = [
            item.content.title,
            item.content.shortDescription,
            item.content.locationText || "",
            item.content.sectorFocus,
            item.content.stageOfDevelopment || ""
          ].flat();
          searchText = searchText.toString().toLocaleLowerCase();
          return {
            id: item.id,
            uuid: item.uuid,
            title: item.content.title,
            description: item.content.shortDescription,
            location:
              item.content.locationText ||
              item.content.headquarterLocationText ||
              item.content.company,
            company: item.content.company || "",
            mapPosition: item.content.locationLatitude
              ? item.content.locationLatitude +
                "," +
                item.content.locationLongitude
              : "12.554764,17.308688",
            lat: item.content.locationLatitude || "12.554764",
            lon: item.content.locationLongitude || "17.308688",
            image: item.content.investorLogo || item.content.backgroundImage,
            startDate: item.content.projectTimelineStart || "",
            startEnd: item.content.projectTimelineEnd || "",
            sector: Array.isArray(item.content.sectorFocus)
              ? item.content.sectorFocus
              : item.content.sectorFocus.split("convertIT"),
            region: item.content.geographicalFocus || [],
            stageOfDevelopment: item.content.stageOfDevelopment || [],
            availableFinancing: item.content.availableFinancing || [],
            investmentAvailable: item.content.investmentAvailable || 0,
            targetReturn: item.content.targetReturn || 0,
            url: "/" + item.full_slug,
            search: searchText
          };
        });
      })
      .flat();
  },
  getAllNews: async function(api, version) {
    let per_page = 10;
    let requests = [];
    let Storyblok = api.$storyapi;
    let initial = await Storyblok.client.head("cdn/stories/", {
      params: {
        per_page: 1,
        starts_with: "news/",
        sort_by: "content.date:desc",
        version: version,
        is_startpage: 0,
        page: 1,
        token: Storyblok.accessToken,
        cv: new Date()
      }
    });
    let totalPages = Math.ceil(initial.headers.total / per_page);
    for (let i = 1; i <= totalPages; i++) {
      requests.push(
        Storyblok.get("cdn/stories/", {
          per_page: per_page,
          version: version,
          is_startpage: 0,
          starts_with: "news/",
          sort_by: "content.date:desc",
          page: i,
          cv: new Date()
        })
      );
    }
    let responses = await Promise.all(requests);

    return responses
      .map(res => {
        return res.data.stories.map(news => {
          return {
            id: news.id,
            uuid: news.uuid,
            title: news.content.title,
            label: news.content.label,
            content: news.content.description,
            url: news.content.externalUrl || "/" + news.full_slug,
            extUrl: news.content.externalUrl || "",
            image: news.content.image,
            date: news.content.date
          };
        });
      })
      .flat();
  },
  getAllCeoTestimonials: async function(api, version) {
    let per_page = 10;
    let requests = [];
    let Storyblok = api.$storyapi;
    let initial = await Storyblok.client.head("cdn/stories/", {
      params: {
        per_page: 1,
        starts_with: "terra-carta-seal/ceo-testimonials/",
        sort_by: "content.name:desc",
        version: version,
        page: 1,
        token: Storyblok.accessToken,
        cv: new Date()
      }
    });
    let totalPages = Math.ceil(initial.headers.total / per_page);
    for (let i = 1; i <= totalPages; i++) {
      requests.push(
        Storyblok.get("cdn/stories/", {
          per_page: per_page,
          version: version,
          starts_with: "terra-carta-seal/ceo-testimonials/",
          sort_by: "content.name:desc",
          page: i,
          cv: new Date()
        })
      );
    }
    let responses = await Promise.all(requests);

    return responses
      .map(res => {
        return res.data.stories.map(testimonial => {
          return {
            id: testimonial.id,
            uuid: testimonial.uuid,
            label: testimonial.content.label,
            name: testimonial.content.name,
            title: testimonial.content.title,
            category: testimonial.content.category,
            videoPosterImage: testimonial.content.videoPosterImage,
            videoUrl: testimonial.content.videoUrl || "",
            search:
              testimonial.content.label +
              " " +
              testimonial.content.name +
              " " +
              testimonial.content.title
          };
        });
      })
      .flat();
  },
  api: function(api, version, fullSlug, context) {
    const buildTerraX = api.$config.buildTerraX;
    if (!buildTerraX && fullSlug.includes("terra-carta-x-change")) {
      context.error({
        statusCode: 404,
        message: "Failed to receive content form api"
      });
    } else {
      return api.$storyapi
        .get(`cdn/stories/${fullSlug}`, {
          version: version,
          resolve_relations:
            "LayoutTestimonials.ceoTestimonials,CompSealTestimonials.ceoTestimonials" // remember no space after comma
        })
        .then(res => {
          return res.data.story;
        })
        .catch(res => {
          if (!res.response) {
            console.error(res);
            context.error({
              statusCode: 404,
              message: "Failed to receive content form api"
            });
          } else {
            console.error(res.response.data);
            context.error({
              statusCode: res.response.status,
              message: res.response.data
            });
          }
        });
    }
  }
};
// Not in use anymore, but can be used for reference
// apiNews: function (api, version) {
//   const settings = {
//     starts_with: "news/",
//     version: version,
//     sort_by: "content.date:desc",
//     per_page: 100,
//   };
//   return api.$storyapi
//     .get("cdn/stories", settings)
//     .then(res => {
//       return res.data.stories.map((news) => {
//         return {
//           id: news.id,
//           uuid: news.uuid,
//           title: news.content.title,
//           label: news.content.label,
//           content: news.content.description,
//           url: news.content.externalUrl || '/' + news.full_slug,
//           extUrl: news.content.externalUrl || "",
//           image: news.content.image,
//           date: news.content.date
//         }
//       });
//     })
//     .catch(res => {
//       if (!res.response) {
//         console.error(res);
//         context.error({
//           statusCode: 404,
//           message: "Failed to receive content form api"
//         });
//       } else {
//         console.error(res.response.data);
//         context.error({
//           statusCode: res.response.status,
//           message: res.response.data
//         });
//       }
//     });
// },
