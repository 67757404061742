<template>
  <div :class="['globe', { draggable: cursorCollision, locked }]">
    <IconLoader class="loader" />
    <canvas ref="canvas" :class="{ draw }"></canvas>
    <div
      :class="['callouts', { showCallouts, squareCallouts, draw }]"
      ref="callouts"
    ></div>
    <div :class="['hitTest']" v-if="cursorCollision && false">
      <p>{{ dataType }}</p>
      <p>World pos:</p>
      <p>{{ cursorCollision.point[0].toString().substring(0, 7) }} X</p>
      <p>{{ cursorCollision.point[1].toString().substring(0, 7) }} Y</p>
      <p>{{ cursorCollision.point[2].toString().substring(0, 7) }} Z</p>
      <p>Lat/long pos:</p>
      <p>{{ cursorLatLon.lat.toString().substring(0, 7) }} Lat</p>
      <p>{{ cursorLatLon.lon.toString().substring(0, 7) }} Lon</p>
    </div>
    <TerraXGlobeModal
      v-if="selectedPin && isLandscapeOrientation"
      :styling="modalPosition"
      :x="modalX"
      :y="modalY"
      :containerBounding="bounding"
      ref="modal"
    ></TerraXGlobeModal>
    <client-only>
      <span class="fps" v-if="debug">{{ Math.round(fps) }}</span>
      <dat-gui
        v-if="debug"
        :closed="true"
        closeText="Close controls"
        openText="Open controls"
        closePosition="top"
      >
        <dat-color v-model="uContinentColor" label="Regions highlight" />
        <dat-folder label="Atmosphere">
          <dat-color v-model="uAtmosphereColor" label="Color" />
          <dat-number
            v-model="uAtmosphereStrength"
            label="Strength"
            :min="0.0"
            :max="4"
            :step="0.01"
          />
        </dat-folder>
        <dat-folder label="Surface">
          <dat-number
            v-model="uNormalStrength"
            label="Height"
            :min="0.0"
            :max="2.0"
            :step="0.01"
          />
        </dat-folder>
        <dat-folder label="Sun">
          <dat-number
            v-model="u_LightLat"
            label="Latitude offset"
            :min="-0.5"
            :max="0.5"
            :step="0.01"
          />
          <dat-number
            v-model="u_LightLon"
            label="Longitude offset"
            :min="-Math.PI"
            :max="Math.PI"
            :step="0.01"
          />
          <dat-color v-model="uSpecularColor" label="Color" />
          <dat-number
            v-model="uSpecularSize"
            label="Size"
            :min="0"
            :max="1"
            :step="0.01"
          />
          <dat-number
            v-model="uSpecularDetail"
            label="Detail"
            :min="0"
            :max="1"
            :step="0.01"
          />
          <dat-number
            v-model="uSpecularStrength"
            label="Strength"
            :min="0"
            :max="2"
            :step="0.01"
          />
        </dat-folder>
        <dat-folder label="Animation">
          <dat-number
            v-model="animationLongitudeSpeed"
            label="Speed Longitude"
            :min="0"
            :max="0.1"
            :step="0.01"
          />
        </dat-folder>
      </dat-gui>
    </client-only>
  </div>
</template>

<script>
import * as _helpers from "./globeHelpers.js";
import { InOutCubic } from "./easingFunctions.js";

let GK;
let POIPinCallout;
if (process.client) {
  GK = require("~/modules/globekit.esm.js");
  POIPinCallout = require("~/modules/PinCallout.js").POIPinCallout;
}
import TerraXGlobeModal from "@/components/terra-x/globe/TerraXGlobeModal";

import IconLoader from "@/components/icons/IconLoader.vue";

export default {
  name: "ITerraXGlobe",
  props: {
    visible: {
      type: Boolean,
    },
  },
  components: {
    TerraXGlobeModal,
    IconLoader,
  },
  data() {
    return {
      debug: false,
      texturePaths: [
        "/globe/assets/day_normal_nightR_coastG_sdfB-v11_512.jpg",
        "/globe/assets/day_normal_nightR_coastG_sdfB-v11_2048.jpg",
        "/globe/assets/day_normal_nightR_coastG_sdfB-v11_4096.jpg",
        "/globe/assets/day_normal_nightR_coastG_sdfB-v11_8192.jpg",
      ],
      loadedTextures: [],
      textureCache: {},
      globeVert: require("~/assets/globe/glsl/globe.vert"),
      globeFrag: require("~/assets/globe/glsl/globe.frag"),
      globeVertFallback: require("~/assets/globe/glsl/globeFallback.vert"),
      globeFragFallback: require("~/assets/globe/glsl/globeFallback.frag"),
      quadVert: require("~/assets/globe/glsl/quad.vert"),
      quadFrag: require("~/assets/globe/glsl/quad.frag"),
      pinVert: require("~/assets/globe/glsl/pin.vert"),
      pinFrag: require("~/assets/globe/glsl/pin.frag"),
      animation: {
        animate: true,
        latitude: {
          // Vertical
          speed: 0.05, // Default: 0.5
          variance: 100, // Default: 100, Above/Below equator total
          start: 0, // Above equator
        },
        longitude: {
          // Horizontal
          speed: 0.03, // Default: 0.5
          start: -90,
        },
      },
      movement: {
        poleBufferThreshold: 18, // Default: 18
        panDeltaScale: 30, // Default: 42
        velocityDeltaScale: 1150, // Default: 750
        springStrength: 0.25, // Default: 0.2
        drag: 0.875, // Default: 0.875
      },
      options: {
        apiKey:
          "gk_a71308f5e3ecd34db7d16274949b8bdb6fae5e54b66363e9066084eaec2c562f7bb4a296c7caf663d31c1bfad2349c73e8d8f20d50b5515a318b63884d754b35",
        wasmPath: "/globe/gkweb_bg.wasm",
        attributes: {
          alpha: false,
        },
        clearColor: [0, 0, 0, 0], // Transparent background
      },
      pins: [],
      bounding: null,
      gkview: null,
      scene: null,
      camera: null,
      textureSat: false,
      interactionController: null,
      earth: null,
      movementModel: null,
      cursorNormalized: [0, 0],
      cursorHitTest: false,
      cursorCollision: false,
      cursorLatLon: { lat: 0, lon: 0 },
      cursorLatLonNormalized: { lat: 0, lon: 0 },
      cursor: null,

      cameraEye: [0, 0, 0], // used for hacking together the actual update loop. If camera eye changes, it's a new rendered frame... seriously

      readyToDraw: 0,
      calloutsDefs: [],
      points: null,
      showCallouts: false,
      squareCallouts: false,
      modalPosition: "",
      modalX: "0",
      modalY: "0",

      canHover: false,
      landscapeOrientation: true,

      altitude: {
        current: 3,
        // locked: 6,
        locked: 5,
        unlocked: 3,
        filterOpen: 4,
      },

      u_LightLatMobile: 0.22,
      u_LightLonMobile: -1.27,

      u_LightLatDesktop: 0.22,
      u_LightLonDesktop: 1.27,

      u_LightLat: 0,
      u_LightLon: 0,

      uContinentColor: `#07f5ca`,

      uAtmosphereColor: `#5397e0`,
      uAtmosphereStrength: 1.34,

      uNormalStrength: 1.0,
      //D_cloudStrength: 0.0, // 0.9
      //D_cloudShadowStrength: 0.0, // 0.3
      //D_cloudSpeed: 0.005,
      uSpecularSize: 0.988,
      uSpecularColor: `#bfe8ff`,
      uSpecularDetail: 0.52,
      uSpecularStrength: 1.0,

      uPointAndTime: [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ],

      animationDurationIn: 2000,
      animationDurationOut: 1500,

      previousContinent: -10,
      activeContinent: -10,
      regions: [
        "asia",
        "americas_north-america",
        "americas_south-america",
        "africa",
        "europe",
        "australia",
        "antarctica",
      ],

      quality: 0,
      lastQualityUpgrade: 0,
      qualityUpgradeDelay: 2000,
      qualityMax: 3,
      fps: 0,
      fpsArray: [0, 0, 0, 0],
      frames: 0,
    };
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
    selectedPin() {
      return this.$store.getters["terrax/getSelectedPin"]();
    },
    selectedPinDetails() {
      return this.$store.getters["terrax/getSelectedPinDetails"]();
    },
    isLandscapeOrientation() {
      return this.$store.getters["terrax/isLandscapeOrientation"]();
    },
    dataType() {
      const type = this.$store.getters["terrax/getType"]();
      // console.log("dataType", type)
      return type;
    },
    filteredData() {
      const data = this.$store.getters["terrax/filteredData"]();
      // console.log("filteredData", data.output)
      return data;
    },
    cleanedData() {
      // Filter away data with undefined lat or lon
      const data = this.filteredData.output.filter((el) => {
        const isUndefined = el.mapPosition.includes("undefined");
        if (isUndefined) {
          return false;
        }
        return true;
      });
      return data;
    },
    locked() {
      return this.$store.state.layout == "LayoutTerraX";
    },
    isInvestors() {
      return this.dataType == "investors";
    },
    isFilterOpen() {
      return this.$store.getters["terrax/isFilterOpen"]();
    },
    draw() {
      return this.readyToDraw >= 3;
    },
    uniformLatLongs() {
      // Get uniform world positions
      const positions = _helpers.getUniformPositions(1500, 1);
      // Convert the world positions into latLon positions
      for (let i = 0; i < positions.length; i++) {
        positions[i] = GK.GKUtils.latLonFromWorld(positions[i]);
        // We add a flag to show if this position has been taken
        positions[i].taken = false;
      }

      return positions;
    },
    geoJson() {
      if (!process.client) {
        return;
      }

      // Create a list of map features
      const features = this.cleanedData.map((el) => {
        const latLon = el.mapPosition.split(",");
        const feature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: latLon,
          },
          properties: el,
        };
        // We make sure to create a location to add distances
        feature.properties.distances = [];
        feature.properties.lat = latLon[0];
        feature.properties.lon = latLon[1];
        return feature;
      });

      // Calculate distances between all points
      // A max angle of 15 takes comparison of points from 2000 down to ca. 40 !!!
      const maxLatLonAngle = 15;
      for (let i = 0; i < features.length; i++) {
        const feat = features[i].properties;
        // Calculate the distance between the feature and all the uniform positions
        for (let u = 0; u < this.uniformLatLongs.length; u++) {
          const uni = this.uniformLatLongs[u];
          // Crudely filter away too unrealistic coordinates before doing expensive distance calculations
          // If latitude is too different
          const lat = Math.abs(feat.lat - uni.lat);
          if (lat > maxLatLonAngle) {
            continue;
          }
          // If longitude is too different
          const lon = Math.abs(feat.lon - uni.lon);
          if (lon > maxLatLonAngle && lon < 360 - maxLatLonAngle) {
            continue;
          }
          const distance = GK.GKUtils.distanceBetweenPoints(
            {
              lat: features[i].properties.lat,
              lon: features[i].properties.lon,
            },
            {
              lat: this.uniformLatLongs[u].lat,
              lon: this.uniformLatLongs[u].lon,
            }
          );
          // Save the distance to the other point on both points, just for good measure
          features[i].properties.distances.push({
            distance: distance,
            positionID: u,
          });
        }
        // Sort distances, nearest first
        features[i].properties.distances.sort((a, b) => {
          return a.distance - b.distance;
        });
        // console.log(features[i].properties.distances);
        // Adjust lat lon to the first position in the list that hasn't already been taken by another feature
        for (let u = 0; u < features[i].properties.distances.length; u++) {
          const positionID = features[i].properties.distances[u].positionID;
          if (this.uniformLatLongs[positionID].taken == false) {
            this.uniformLatLongs[positionID].taken = true;
            features[i].geometry.coordinates[0] =
              this.uniformLatLongs[positionID].lat;
            features[i].geometry.coordinates[1] =
              this.uniformLatLongs[positionID].lon;
            break;
          }
          // If there aren't any more potential locations left, the feature simply keep its position
        }
      }
      const geoJson = {
        features: features,
        type: "FeatureCollection",
      };
      // console.log('geoJson', geoJson)
      return geoJson;
    },
    // FOR DEBUG CONTROLS
    animationLongitudeSpeed: {
      get: function () {
        return this.animation.longitude.speed;
      },
      set: function (data) {
        this.animation.longitude.speed = data;
        this.animationSettings();
        this.gkview.ambientController.resumeMotionAnimated({});
      },
    },
  },
  watch: {
    layout: {
      handler: function (newVal) {
        if (!this.earth) {
          return;
        }
        this.animateSunLon(newVal);
      },
    },
    visible(newVal) {
      if (!newVal) {
        // deselect active pin and hide pins when the globe is hidden - that makes the transition back to the globe more smooth
        this.deselectSelectedPin();
        this.showCallouts = false;
      }
    },
    filteredData(val) {
      this.resetUniformPositions();
      this.deselectSelectedPin();
      this.showCallouts = false;
      setTimeout(() => {
        this.setCallouts();
      }, 200);
    },
    geoJson(val) {
      return;
      console.log("geoJson", val);
      for (let i = 0; i < this.gkview.drawqueue.length; i++) {
        if (this.gkview.drawqueue[i].datastore) {
          this.gkview.drawqueue[i].shouldDraw = false;
        }
      }

      const points = new GK.Points({
        useTexture: true,
        texture: "/globe/assets/disk.png",
      });

      points.addGeojson(this.geoJson);
      points.setInteractive(true, true, false);
      for (let i = 0; i < this.geoJson.features.length; i++) {
        points.updatePoint(i, {
          size: 40,
        });
      }
      this.gkview.addDrawable(points);
    },
    uAtmosphereStrength(val) {
      this.earth.material.uniforms.uAtmosphereStrength = val;
    },
    uAtmosphereColor(val) {
      this.earth.material.uniforms.uAtmosphereColor = this.setColor(val);
    },

    uContinentColor(val) {
      this.earth.material.uniforms.uContinentColor = this.setColor(val);
    },

    uNormalStrength(val) {
      this.earth.material.uniforms.uNormalStrength = val;
    },

    uSpecularSize(val) {
      this.earth.material.uniforms.uSpecularSize = val;
    },
    uSpecularColor(val) {
      this.earth.material.uniforms.uSpecularColor = this.setColor(val);
    },
    uSpecularDetail(val) {
      this.earth.material.uniforms.uSpecularDetail = val;
    },
    uSpecularStrength(val) {
      this.earth.material.uniforms.uSpecularStrength = val;
    },

    u_LightLat(val) {
      if (!this.earth) {
        return;
      }
      const lon = this.earth.material.uniforms.uLightLatLon[1];
      this.earth.material.uniforms.uLightLatLon = [val, lon];
    },
    u_LightLon(val) {
      if (!this.earth) {
        return;
      }
      const lat = this.earth.material.uniforms.uLightLatLon[0];
      this.earth.material.uniforms.uLightLatLon = [lat, val];
    },

    previousContinent(val) {
      this.earth.material.uniforms.uContinentOutIDTime = [
        val,
        this.earth.material.uniforms.time,
      ];
    },
    activeContinent(val) {
      this.earth.material.uniforms.uContinentInIDTime = [
        val,
        this.earth.material.uniforms.time,
      ];
    },
    draw(val) {
      if (val) {
        this.startDrawing();
      }
    },
    // locked(val) {
    //   if (val) {
    //     this.resetUniformPositions();
    //     this.deselectSelectedPin();
    //   }
    // },
    locked(val) {
      if (val) {
        // TODO:
        // this.gkview.movementModel.setAlt(8);
        //setTimeout(() => {
        this.gkview.animationController.animateAlt(this.altitude.locked, {
          duration: this.animationDurationOut,
          ease: InOutCubic,
          onComplete: () => {
            this.updateInteractionBoundaries();
            setTimeout(() => {
              this.updateInteractionBoundaries();
            }, 1000);
          },
        });
        //}, 500)
      } else {
        this.gkview.animationController.animateAlt(this.altitude.unlocked, {
          duration: this.animationDurationIn,
          ease: InOutCubic,
          onComplete: () => {
            this.updateInteractionBoundaries();
          },
        });
      }
    },
    isFilterOpen(val) {
      if (val) {
        // TODO:
        // this.gkview.movementModel.setAlt(8);
        //setTimeout(() => {
        this.gkview.animationController.animateAlt(this.altitude.filterOpen, {
          duration: this.animationDurationOut,
          ease: InOutCubic,
          onComplete: () => {
            this.updateInteractionBoundaries();
            setTimeout(() => {
              this.updateInteractionBoundaries();
            }, 1000);
          },
        });
        //}, 500)
      } else {
        this.gkview.animationController.animateAlt(this.altitude.unlocked, {
          duration: this.animationDurationIn,
          ease: InOutCubic,
          onComplete: () => {
            this.updateInteractionBoundaries();
          },
        });
      }
    },
    isLandscapeOrientation: {
      immediate: true,
      handler(e) {
        if (e) {
          this.u_LightLat = this.u_LightLatDesktop;
          this.u_LightLon = this.u_LightLonDesktop;
        } else {
          this.u_LightLat = this.u_LightLatMobile;
          this.u_LightLon = this.u_LightLonMobile;
        }

        if (this.earth) {
          this.animateSunLon(this.layout);
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      if ("requestIdleCallback" in window) {
        this.canRequestIdleCallback = true;
      }
      this.canHover = !window.matchMedia("(hover: none)").matches;

      if (this.$refs.canvas) {
        this.init();
        this.animationSettings();
        this.movementSettings();
        this.addAtmosphere(1.05, "/globe/assets/atmosphere-BG.png");
        this.addEarth();
        this.addCallouts();

        this.onMouseMove();
        this.onMouseDown();
        this.onMouseUp();

        this.onResize();

        requestAnimationFrame(this.onUpdate);
        document.addEventListener("keydown", this.toggleDebug);
        document.addEventListener("globekitFrame", this.onGlobekitFrame);
      }
    }, 0);
  },
  activated() {
    console.log("ACTIVATED GLOBE");
  },
  deactivated() {
    console.log("DEACTIVATED GLOBE");
  },
  methods: {
    toggleDebug(e) {
      // 72 = h
      if (e.keyCode == 72) {
        this.debug = !this.debug;
      }
    },
    init() {
      this.gl = this.$refs.canvas.getContext("webgl");
      console.log("gl", this.gl);
      this.gkview = new GK.GlobeKitView(
        this.$refs.canvas,
        this.options,
        this.onInitCB
      );
      console.log("gkview", this.gkview);
      this.scene = this.gkview.scene;
      this.interactionController = this.gkview.interactionController;
      console.log("interactionController", this.interactionController);
      this.camera = this.scene.camera;
      console.log("camera", this.camera);
    },
    movementSettings() {
      this.gkview.movementModel.poleBufferThreshold =
        this.movement.poleBufferThreshold;

      this.gkview.movementModel.panDeltaScale = this.movement.panDeltaScale;
      this.gkview.movementModel.velocityDeltaScale =
        this.movement.velocityDeltaScale;

      this.gkview.movementModel.springStrength = this.movement.springStrength;
      this.gkview.movementModel.drag = this.movement.drag;

      if (this.locked) {
        this.altitude.current = this.altitude.locked;
      } else {
        this.altitude.current = this.altitude.unlocked;
      }
      this.gkview.movementModel.setAlt(this.altitude.current);
      //this.gkview.onUpdate = this.onUpdate;
    },

    animationSettings() {
      if (this.animation.animate == true) {
        this.gkview.ambientController.isEnabled = false;
        this.gkview.ambientController.latitudeSpeed =
          this.animation.latitude.speed;
        this.gkview.ambientController.latitudeVariance =
          this.animation.latitude.variance;

        this.gkview.ambientController.longitudeSpeed =
          this.animation.longitude.speed;

        this.gkview.animationController.animateLatLon(
          this.animation.latitude.start,
          this.animation.longitude.start,
          {
            duration: 0,
            onComplete: () => {
              this.gkview.ambientController.isEnabled = true;
            },
          }
        );
        console.log("animationController", this.gkview.animationController);
      }
    },

    createTexture() {
      console.log("createTexture");
      this.globeTexture = this.gl.createTexture();
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.globeTexture);

      // Because images have to be downloaded over the internet
      // they might take a moment until they are ready.
      // Until then put a single pixel in the texture so we can
      // use it immediately. When the image has finished downloading
      // we'll update the texture with the contents of the image.
      const level = 0;
      const internalFormat = this.gl.RGBA;
      const width = 1;
      const height = 1;
      const border = 0;
      const srcFormat = this.gl.RGBA;
      const srcType = this.gl.UNSIGNED_BYTE;
      const pixel = new Uint8Array([0, 0, 255, 255]); // opaque blue
      this.gl.texImage2D(
        this.gl.TEXTURE_2D,
        level,
        internalFormat,
        width,
        height,
        border,
        srcFormat,
        srcType,
        pixel
      );

      for (let i = 0; i < this.texturePaths.length; i++) {
        this.loadedTextures[i] = null;
        this.getTexture(this.texturePaths[i], { anisotropy: 2 }, (result) => {
          this.loadedTextures[i] = result;
          // Set first texture instantly
          if (!this.textureSat) {
            this.setTexture(level, internalFormat, srcFormat, srcType);
            this.textureSat = true;
            setTimeout(() => {
              this.readyToDraw++;
            }, 1000);
          }
          // In case all the rest of the textures are pretty much ready in a queue, leave space for the best one to be set
          else {
            if (this.timeout) {
              clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
              this.setTexture(level, internalFormat, srcFormat, srcType);
            }, 200);
          }
        });
      }
    },

    getTexture(src, options = {}, callback) {
      console.log("getTexture", src);

      // generateMipmaps = true
      if (this.textureCache[src]) {
        callback(this.textureCache[src]);
        //return this.textureCache[src]
      }
      //const texture = new OGL.Texture(this.o.gl, options)
      const image = new Image();
      //image.crossOrigin = "anonymous";
      this.textureCache[src] = image;
      image.onload = () => {
        //texture.image = image
        // console.log("getTexture - loaded", src);
        callback(image);
      };
      image.src = src;
      //return texture
    },
    isPowerOf2(value) {
      return (value & (value - 1)) == 0;
    },

    setTexture(level, internalFormat, srcFormat, srcType) {
      console.log("setTexture", this.loadedTextures);

      for (let i = this.loadedTextures.length; i > 0; i--) {
        if (this.loadedTextures[i - 1] != null) {
          this.gl.bindTexture(this.gl.TEXTURE_2D, this.globeTexture);
          this.gl.texImage2D(
            this.gl.TEXTURE_2D,
            level,
            internalFormat,
            srcFormat,
            srcType,
            this.loadedTextures[i - 1]
          );

          // WebGL1 has different requirements for power of 2 images
          // vs non power of 2 images so check if the image is a
          // power of 2 in both dimensions.
          if (
            this.isPowerOf2(this.loadedTextures[i - 1].width) &&
            this.isPowerOf2(this.loadedTextures[i - 1].height)
          ) {
            // Yes, it's a power of 2. Generate mips.
            this.gl.generateMipmap(this.gl.TEXTURE_2D);
            this.gl.texParameteri(
              this.gl.TEXTURE_2D,
              this.gl.TEXTURE_MIN_FILTER,
              this.gl.LINEAR_MIPMAP_LINEAR
            );
            this.gl.texParameteri(
              this.gl.TEXTURE_2D,
              this.gl.TEXTURE_MAG_FILTER,
              this.gl.LINEAR
            );
          } else {
            // No, it's not a power of 2. Turn off mips and set
            // wrapping to clamp to edge
            this.gl.texParameteri(
              this.gl.TEXTURE_2D,
              this.gl.TEXTURE_MIN_FILTER,
              this.gl.LINEAR
            );
          }
          this.gl.texParameteri(
            this.gl.TEXTURE_2D,
            this.gl.TEXTURE_WRAP_S,
            this.gl.CLAMP_TO_EDGE
          );
          this.gl.texParameteri(
            this.gl.TEXTURE_2D,
            this.gl.TEXTURE_WRAP_T,
            this.gl.CLAMP_TO_EDGE
          );

          this.earth.material.uniforms.uTexture = this.globeTexture;

          return;
        }
      }
    },

    loadImage(url, callback) {
      var image = new Image();
      image.src = url;
      image.onload = callback;
      return image;
    },

    setEarthShaders(quality) {
      console.log("GLOBE", "setEarthShaders", this.quality, quality);

      if (this.quality == quality) {
        console.log("GLOBE", "setEarthShaders", "quality is same - do nothing");
        return;
      }
      console.log("GLOBE", "setEarthShaders", "quality is new - set texture");

      this.quality = quality;

      if (!this.earth) {
        return;
      }
      const platform = window.navigator.platform;
      const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
      if (windowsPlatforms.indexOf(platform) !== -1) {
        console.log("Windows shader");
        this.earth.material.shaderStrings.vertex = this.globeVertFallback;
        this.earth.material.shaderStrings.fragment = this.globeFragFallback;
        return;
      }
      if (quality == 1) {
        this.earth.material.shaderStrings.fragment = this.globeFrag;
        this.earth.material.shaderStrings.vertex = this.globeVert;
        if (this.shadersSetOnce) {
          this.earth.material.init(this.gl);
        }
      } else if (quality == 2) {
        this.earth.material.shaderStrings.fragment = `
          #define Q_SPECULAR
          ${this.globeFrag}`;
        this.earth.material.shaderStrings.vertex = `
          #define Q_SPECULAR
          ${this.globeVert}`;

        if (this.shadersSetOnce) {
          this.earth.material.init(this.gl);
        }
      } else if (quality == 3) {
        this.earth.material.shaderStrings.fragment = `
          #define Q_SPECULAR
          #define Q_DIFFUSE
          ${this.globeFrag}`;
        this.earth.material.shaderStrings.vertex = `
          #define Q_SPECULAR
          #define Q_DIFFUSE
          ${this.globeVert}`;

        if (this.shadersSetOnce) {
          this.earth.material.init(this.gl);
        }
      }

      this.shadersSetOnce = true;
    },

    addEarth() {
      console.log("GLOBE", "addEarth");
      // Create a sphere with a texture
      this.earth = new GK.Icosphere("/globe/assets/white-pixel.png");
      console.log("GLOBE", "create Icosphere with white-pixel texture ");

      this.earth.init(this.gl, () => {
        console.log("GLOBE", "addEarth", "GK.Icosphere.onInit");
        this.createTexture();
      });

      // reset quality
      console.log("GLOBE", "addEarth", "reset quality to", 0);
      this.quality = 0;

      this.setEarthShaders(1);

      this.earth.material.uniforms.uAtmosphereStrength =
        this.uAtmosphereStrength;
      this.earth.material.uniforms.uAtmosphereColor = this.setColor(
        this.uAtmosphereColor
      );
      this.earth.material.uniforms.uContinentColor = this.setColor(
        this.uContinentColor
      );
      //this.earth.material.uniforms.D_cloudStrength = this.D_cloudStrength
      //this.earth.material.uniforms.D_cloudShadowStrength = this.D_cloudShadowStrength
      //this.earth.material.uniforms.D_cloudSpeed = this.D_cloudSpeed

      this.earth.material.uniforms.uLightLatLon = [
        this.u_LightLat,
        this.u_LightLon,
      ];

      this.animateSunLon(this.layout);
      this.earth.material.uniforms.uNormalStrength = this.uNormalStrength;
      this.earth.material.uniforms.uSpecularStrength = this.uSpecularStrength;
      this.earth.material.uniforms.uSpecularDetail = this.uSpecularDetail;
      this.earth.material.uniforms.uSpecularSize = this.uSpecularSize;
      this.earth.material.uniforms.uSpecularColor = this.setColor(
        this.uSpecularColor
      );

      this.earth.material.uniforms.uContinentInIDTime = [-10, 0];
      this.earth.material.uniforms.uContinentOutIDTime = [-10, 0];
      //this.earth.material.uniforms.u_normalMatrix = []

      //this.earth.material.uniforms.u_normalMatrix = this.normalFromMat4()
      this.earth.material.uniforms.uPointAndTime = [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
      ];
      this.earth.setInteractive(true, true, false);

      //this.loadImage('/globe/assets/day_normal_nightR_coastR_cloudsB-v5.jpg', () => {

      //})

      this.gkview.addDrawable(this.earth, () => {
        // We want to remove the visual glitches that appear along the outer edge
        // and especially when the earth scales down.

        // Set the Anisotropy value to the maximum possible.
        // This removes stretch glitches towards the poles and allow for a cheaper mip map quality
        // which also is blurring the texture less
        const extension =
          this.gl.getExtension("EXT_texture_filter_anisotropic") ||
          this.gl.getExtension("MOZ_EXT_texture_filter_anisotropic") ||
          this.gl.getExtension("WEBKIT_EXT_texture_filter_anisotropic");
        if (extension) {
          let max = this.gl.getParameter(
            extension.MAX_TEXTURE_MAX_ANISOTROPY_EXT
          );
          // Very often the max possible anisotropy returned is 16, which is way too much for our need!
          if (max > 2) {
            max = 2;
          }
          this.gl.texParameterf(
            this.gl.TEXTURE_2D,
            extension.TEXTURE_MAX_ANISOTROPY_EXT,
            max
          );
        }

        // Set the Mipmap quality

        // Mipmap filtering from this article:
        // https://webglfundamentals.org/webgl/lessons/webgl-3d-textures.html
        // - NEAREST = choose 1 pixel from the biggest mip
        // - LINEAR = choose 4 pixels from the biggest mip and blend them
        // - NEAREST_MIPMAP_NEAREST = choose the best mip, then pick one pixel from that mip
        // - LINEAR_MIPMAP_NEAREST = choose the best mip, then blend 4 pixels from that mip
        // - NEAREST_MIPMAP_LINEAR = choose the best 2 mips, choose 1 pixel from each, blend them
        // - LINEAR_MIPMAP_LINEAR = choose the best 2 mips. choose 4 pixels from each, blend them

        // TEXTURE_MIN_FILTER = setting used when the size you are drawing is smaller than the largest mip.
        // TEXTURE_MAG_FILTER = setting used when the size you are drawing is larger than the largest mip (only NEAREST and LINEAR are valid settings).

        this.gl.texParameteri(
          this.gl.TEXTURE_2D,
          this.gl.TEXTURE_MIN_FILTER,
          this.gl.LINEAR_MIPMAP_LINEAR
        );
        this.gl.texParameteri(
          this.gl.TEXTURE_2D,
          this.gl.TEXTURE_MAG_FILTER,
          this.gl.LINEAR
        );

        this.gl.texParameteri(
          this.gl.TEXTURE_2D,
          this.gl.TEXTURE_WRAP_S,
          this.gl.CLAMP_TO_EDGE
        );
        this.gl.texParameteri(
          this.gl.TEXTURE_2D,
          this.gl.TEXTURE_WRAP_T,
          this.gl.CLAMP_TO_EDGE
        );

        this.readyToDraw++;
      });

      //console.log(this.earth.material.uniforms.uModelMatrix)
      //this.earth.material.uniforms.u_normalMatrix = this.normalFromMat4(this.earth.material.uniforms.uModelMatrix)

      console.log("earth", this.earth);
    },
    setColor(hex) {
      const array = _helpers.hexToRgb(hex);
      return [array[0] / 255, array[1] / 255, array[2] / 255];
    },
    resetContinents() {
      this.previousContinent = this.activeContinent;
      this.activeContinent = -10;
    },
    resetUniformPositions() {
      if (this.uniformLatLongs.length) {
        if (this.uniformLatLongs[0].lat) {
          for (let i = 0; i < this.uniformLatLongs.length; i++) {
            this.uniformLatLongs[i].taken = false;
          }
        }
      }
    },
    removeDrawable(id) {
      for (let i = 0; i < this.gkview.drawqueue.length; i++) {
        if (this.gkview.drawqueue[i].id == id) {
          this.gkview.drawqueue.splice(i, 1);
        }
      }
    },
    addAtmosphere(scale, path) {
      console.log("GLOBE", "addAtmosphere", path);

      const atmosphere = new GK.Atmosphere({
        texture: path,
      });
      atmosphere.setInteractive(false, false, false);
      atmosphere.nScale = scale;
      this.gkview.addDrawable(atmosphere, () => this.readyToDraw++);
      console.log("atmosphere", atmosphere);
    },

    addCallouts() {
      // Callout manager moves callouts to keep them attached to their points
      this.calloutManager = new GK.CalloutManager(this.$refs.callouts);

      this.$refs.callouts.addEventListener("pinClick", this.onPinClick);
      this.$refs.callouts.addEventListener(
        "deselectPin",
        this.deselectSelectedPin
      );
      this.$refs.callouts.addEventListener("pinHoverIn", this.onPinHoverIn);
      this.$refs.callouts.addEventListener("pinHoverOut", this.onPinHoverOut);

      this.gkview.registerCalloutManager(this.calloutManager);

      this.calloutManager.shouldAutoRemoveCallout = (def) => {
        if (def.calloutClass === POIPinCallout) {
          return false;
        }
        return true;
      };

      this.setCallouts();
    },
    setCallouts() {
      this.squareCallouts = this.isInvestors;
      this.calloutsDefs = this.geoJson.features.map((el) => {
        const callout = new GK.CalloutDefinition(
          el.geometry.coordinates[0],
          el.geometry.coordinates[1] - 90,
          POIPinCallout,
          el
        );
        callout.altitude = 0.1;

        return callout;
      });

      this.calloutManager.replaceCallouts(this.calloutsDefs);

      setTimeout(() => {
        this.showCallouts = true;
      }, 10);
    },

    startDrawing() {
      this.gkview.startDrawing();
      this.gkview.ambientController.resumeMotionAnimated({});
    },
    onGlobekitFrame() {
      return;
      // We check if this frame has already been requested from the globekit callouts
      if (this.newGlobekitFrame) {
        return;
      }
      this.updateFps();

      if (this.selectedPin) {
        this.modalPosition = this.selectedPin.getAttribute("style");
        this.modalX = this.selectedPin.dataset.x;
        this.modalY = this.selectedPin.dataset.y;
      }
      this.newGlobekitFrame = true;
    },
    onUpdate(time) {
      this.updateFps(time);

      if (this.selectedPin) {
        this.modalPosition = this.selectedPin.getAttribute("style");
        this.modalX = this.selectedPin.dataset.x;
        this.modalY = this.selectedPin.dataset.y;
      }
      //console.log(time);
      // Reset the globekit frames every requestAnimationFrame, which allows it to be set again next potential requestAnimationFrame
      //this.newGlobekitFrame = false;
      requestAnimationFrame(this.onUpdate);
    },
    updateFps(time) {
      this.fps = this.calculateFPS();

      if (this.fps >= 59 && this.quality < this.qualityMax) {
        if (this.lastQualityUpgrade + this.qualityUpgradeDelay < time) {
          this.lastQualityUpgrade = time;
          this.setEarthShaders(this.quality + 1);
        }
      }
      if (this.fps <= 45 && this.quality > 1) {
        if (this.lastQualityUpgrade + this.qualityUpgradeDelay < time) {
          this.qualityMax = this.quality - 1;
          this.lastQualityUpgrade = time;
          this.setEarthShaders(this.quality - 1);
        }
      }
    },
    onResize() {
      window.addEventListener("resize", this.resize);
      this.resize();
      this.updateInteractionBoundaries();
      setTimeout(() => {
        this.updateInteractionBoundaries();
      }, 600);
    },
    resize() {
      this.$store.commit(
        "terrax/landscapeOrient",
        window.innerWidth > window.innerHeight
      );
      if (this.canHover) {
        this.updateInteractionBoundaries();
      }
    },
    updateInteractionBoundaries() {
      console.log("updateInteractionBoundaries");
      this.bounding = this.$refs.canvas.getBoundingClientRect();
      this.interactionController.resize();
    },
    onMouseMove() {
      this.$refs.canvas.addEventListener("mousemove", (e) => {
        this.mouseMove(e);
      });
    },
    mouseMove(e) {
      if (!this.draw) {
        return;
      }
      this.cursorNormalized[0] =
        (e.clientX - this.bounding.left) / this.bounding.width;
      this.cursorNormalized[1] =
        (this.bounding.height - (e.clientY - this.bounding.top)) /
        this.bounding.height;

      this.cursorRay = this.camera.getRayFromScreen(
        this.cursorNormalized[0],
        this.cursorNormalized[1]
      );
      this.cursorHitTest = this.earth.hitTest(this.cursorRay);
      this.cursorCollision = this.earth.rayCastFrom(this.cursorRay);

      if (this.cursorCollision) {
        this.cursorLatLon = GK.GKUtils.latLonFromWorld(
          this.cursorCollision.point
        );
        this.cursorLatLonNormalized.lat = (this.cursorLatLon.lat + 90) / 180;
        this.cursorLatLonNormalized.lon = (this.cursorLatLon.lon + 180) / 360;
      }
    },
    onMouseDown() {
      this.$refs.canvas.addEventListener("mousedown", this.mouseDown);
    },
    mouseDown() {
      // update interacion boundaries so that the globe stays draggable even if layout has changed
      this.updateInteractionBoundaries();

      if (!this.cursorCollision.nearest) {
        return;
      }
      this.mouseDownStart = performance.now();
      // Remove the last item in the list of vector4s
      this.uPointAndTime.pop();
      // Add a new item to the beginning of the list of vector4s
      // First 3 attributes are coordinates and the last is the time of the click
      const coord = this.cursorCollision.nearest.normal;
      this.uPointAndTime.unshift([
        coord[0],
        coord[1],
        coord[2],
        this.earth.material.uniforms.time,
      ]);

      this.earth.material.uniforms.uPointAndTime = _helpers.setVectorArray(
        this.uPointAndTime
      );
    },
    onMouseUp() {
      window.addEventListener("mouseup", this.mouseUp);
    },
    mouseUp() {
      const timeDown = performance.now() - this.mouseDownStart;
      if (timeDown < 200) {
        // quick click
        this.mouseQuickClick();
      } else {
        // slow click - happens on drag
        if (this.selectedPin) {
          this.gkview.ambientController.pauseMotionAnimated({});
        }
      }
    },
    mouseQuickClick() {
      const isOverviewPage = this.$store.state.layout == "LayoutTerraX";
      if (isOverviewPage) {
        const path =
          this.$store.state.terrax.routeQuery.type === "projects"
            ? this.$config.terraXProjectsPage
            : this.$config.terraXInvestorsPage;
        this.$router.push({
          path: this.$urlResolver(path),
          query: { globe: true },
        });
      } else {
        this.deselectSelectedPin();
      }
      if (this.selectedPin) {
        this.gkview.ambientController.pauseMotionAnimated({});
      } else {
        this.gkview.ambientController.resumeMotionAnimated({});
      }
    },
    selectPin(pin) {
      const selectedPinEl = pin.srcElement;
      this.gkview.ambientController.pauseMotionAnimated({});
      this.deselectSelectedPin();

      selectedPinEl.classList.add("selected");
      selectedPinEl.isSelected = true;
      this.$store.commit(
        "terrax/setSelectedPinDetails",
        pin.detail.data.properties
      );

      // console.log("selectPin", pin);

      this.$store.commit("terrax/setSelectedPin", selectedPinEl);

      if (this.isInvestors) {
        this.previousContinent = this.activeContinent;
        let regionID;
        for (let i = 0; i < this.selectedPinDetails.region.length; i++) {
          regionID = this.regions.indexOf(this.selectedPinDetails.region[i]);
          if (regionID != -1) {
            break;
          }
        }

        if (regionID) {
          this.activeContinent = regionID;
        }
      }

      this.moveNeightbourPins(pin, selectedPinEl);
    },
    deselectSelectedPin() {
      if (this.selectedPin) {
        this.selectedPin.classList.remove("selected");
        this.selectedPin.isSelected = null;
        this.$store.commit("terrax/setSelectedPinDetails", null);
        this.$store.commit("terrax/setSelectedPin", null);
        this.resetContinents();

        this.resetNeightbourPins();

        setTimeout(() => {
          // check if a new items has been selected - otherwise resume motion to the
          if (!this.selectedPin) {
            this.gkview.ambientController.resumeMotionAnimated({});
          }
        }, 100);
      }
    },
    moveNeightbourPins(selectedPin, selectedPinEl) {
      // MOVE NEIGHBOURS
      // When a feature (pin) is selected/opened we push all overlapping pins aside

      // All neighbours closer than 850 will be pushed to the side

      let maxNeighbourDistance = 1300;
      if (window.innerWidth > this.$breakpoints.tabletPortrait) {
        // desktop
        maxNeighbourDistance = 900;
      }

      // MOVE NEIGHBOURS: 1 - Get position of selected pin
      const selectedId = selectedPin.detail.data.properties.id;
      const selectedGlobeLat = selectedPin.detail.data.geometry.coordinates[1];
      const selectedGlobeLon = selectedPin.detail.data.geometry.coordinates[0];
      // const selectedOriginalLat = selectedPin.detail.data.properties.lat;
      // const selectedOriginalLon = selectedPin.detail.data.properties.lon;

      // MOVE NEIGHBOURS: 2 - Find near neighbours
      const features = this.geoJson.features;
      const neighbourFeatures = [];
      features.forEach((feature) => {
        const featureId = feature.properties.id;
        const featureGlobeLat = feature.geometry.coordinates[1];
        const featureGlobeLon = feature.geometry.coordinates[0];
        // const featureOriginalLat = feature.properties.lat;
        // const featureOriginalLon = feature.properties.lon;

        // skip the feature which is currently selected
        if (selectedId === featureId) {
          return;
        }

        // find real life distance (used for testing when developing)
        // const distOriginal = GK.GKUtils.distanceBetweenPoints(
        //   { lat: selectedOriginalLat, lon: selectedOriginalLon },
        //   { lat: featureOriginalLat, lon: featureOriginalLon }
        // );

        // find distance on globe
        // when features (pins) are created we place them all in a grid to make sure they don't overlap, so the original lat and lon is not their final position on the globe
        const distGlobe = GK.GKUtils.distanceBetweenPoints(
          { lat: selectedGlobeLat, lon: selectedGlobeLon },
          { lat: featureGlobeLat, lon: featureGlobeLon }
        );

        // add all near neighbours
        if (distGlobe < maxNeighbourDistance) {
          neighbourFeatures.push({ id: featureId });
        }
      });

      // MOVE NEIGHBOURS: 3 - Find screen positions
      // find screen position for selected feature
      const selectedPinRect = selectedPinEl.getBoundingClientRect();
      const selectedPinCenter = {
        x: selectedPinRect.x,
        y: selectedPinRect.y,
      };

      // find screen position for all neighbours
      const featureDomElements = this.$refs.callouts.children;
      neighbourFeatures.forEach((featurePin) => {
        const featurePinEl = featureDomElements.namedItem(featurePin.id);
        if (!featurePinEl) return;
        const featurePinRect = featurePinEl.getBoundingClientRect();
        const featurePinCenter = {
          x: featurePinRect.x,
          y: featurePinRect.y,
        };

        // MOVE NEIGHBOURS: 4 - Calculate vector + length
        const a = selectedPinCenter;
        const b = featurePinCenter;
        // calculate vector from a to b
        // https://www.regneregler.dk/vektorer-i-planen-vektor-ud-fra-to-punkter
        const vector = [b.x - a.x, b.y - a.y];

        // calculate length from a to b
        const pythagorean = (sideA, sideB) =>
          Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2));
        const length = pythagorean(a.y - b.y, a.x - b.x);

        // calculate unit vector
        const unitVector = [vector[0] / length, vector[1] / length];

        // calculate how much the feature should be moved
        // features closest to the selected feature should be moved the most
        const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
        const offsetFactor = clamp(1 - length / 100, 0, 1);

        // add css variables - items will then be offset with css transforms
        const pinContainerEl = featurePinEl.children[0];
        pinContainerEl.style.setProperty("--offset-x", unitVector[0]);
        pinContainerEl.style.setProperty("--offset-y", unitVector[1]);
        pinContainerEl.style.setProperty("--offset-factor", offsetFactor);

        // console.log(
        //   "feature",
        //   featurePin.id,
        //   "vector",
        //   vector,
        //   "length",
        //   length,
        //   "unitVector",
        //   unitVector,
        //   "center",
        //   featurePinCenter,
        //   "offsetFactor",
        //   offsetFactor,
        //   pinContainerEl
        // );
      });
    },
    resetNeightbourPins() {
      // reset position of neighbours
      const pinDomElements = Array.from(this.$refs.callouts.children);
      pinDomElements.forEach((pin) => {
        const firstChild = pin.children[0];
        firstChild.style.setProperty("--offset-x", 0);
        firstChild.style.setProperty("--offset-y", 0);
      });
    },
    onInitCB() {},
    onPinClick(e) {
      //setTimeout(() => {

      // deselect active pin
      const selectedPin = e.srcElement;
      if (selectedPin.isSelected) {
        this.deselectSelectedPin();
        return;
      }

      // select new pin
      this.selectPin(e);

      // }, 0)
    },
    onPinHoverIn(e) {
      if (!this.selectedPin) {
        // Turn off the auto animation
        this.gkview.ambientController.pauseMotionAnimated({});
      }
      this.gkview.ambientController.isEnabled = false;
    },
    onPinHoverOut(e) {
      if (!this.selectedPin) {
        // Resume the auto animation
        this.gkview.ambientController.resumeMotionAnimated({});
      }
      this.gkview.ambientController.isEnabled = true;
    },

    callback() {
      this.$emit("callback", "some value");
    },

    // Deduce framerate based on remaining time per frame
    // Goal is 60FPS - this should not be hardcoded!
    fpsCallback(d) {
      // Calculate the actual time the frame took
      // and the according FPS
      var goal = 1000 / 60;
      var elapsed = goal - d.timeRemaining();
      this.FPSrIC = (goal * 60) / elapsed;

      // Tell the FPS meter that we are over 60FPS
      this.hasrICBeenCalledForThisFrame = true;
    },

    // Every 1000ms, let's update the framerate
    calculateFPS() {
      var t = performance.now();
      if (!this.startTime) {
        this.fpsAverage = 0;
        this.startTime = t;
      }
      var dt = t - this.startTime;

      // if elapsed time is greater than 1s
      if (dt > 300) {
        // calculate the frames drawn over the period of time
        const fps = (this.frames * 1000) / dt;
        this.fpsArray.push(fps);
        this.fpsArray.shift();
        // and restart the values
        this.frames = 0;
        this.startTime = t;

        let sum = 0;
        for (let i = 0; i < this.fpsArray.length; i++) {
          sum += this.fpsArray[i];
        }
        this.fpsAverage = sum / this.fpsArray.length;
      }
      this.frames++;

      return this.fpsAverage;
    },
    animateSunLon(layout) {
      const duration = 2000;
      const lat = this.earth.material.uniforms.uLightLatLon[0];
      const initialLon = this.earth.material.uniforms.uLightLatLon[1];

      let targetLon = this.u_LightLon;
      if (layout == "LayoutTerraX" && !this.isLandscapeOrientation) {
        targetLon = 0;
      }
      if (initialLon === targetLon) return;
      // console.log(
      //   'GLOBE',
      //   'animateSunLon'
      //   "TARGET",
      //   targetLon,
      //   "INIT",
      //   initialLon,
      //   "u_LightLon",
      //   this.u_LightLon
      // );

      // tween to new value
      const startTime = new Date();

      const tween = () => {
        var delta = Math.min(1, (new Date() - startTime) / duration);
        // delta is now a number in the range [0 ... 1]
        let newLon = initialLon + delta * (targetLon - initialLon);
        this.earth.material.uniforms.uLightLatLon = [lat, newLon];
        if (delta < 1) requestAnimationFrame(tween);
      };

      requestAnimationFrame(tween);
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.toggleDebug);
    this.$refs.callouts.removeEventListener("pinClick", this.onPinClick);
    this.$refs.callouts.removeEventListener(
      "deselectPin",
      this.deselectSelectedPin
    );
    this.$refs.callouts.removeEventListener("pinHoverIn", this.onPinHoverIn);
    this.$refs.callouts.removeEventListener("pinHoverOut", this.onPinHoverOut);

    if (this.calloutManager) {
      this.calloutManager.release();
      this.calloutManager.removeAllCallouts();
      this.calloutManager.release();
    }

    window.removeEventListener("resize", this.resize);
    window.removeEventListener("mouseup", this.mouseUp);
    this.$refs.canvas.removeEventListener("mousedown", this.mouseDown);
    this.$refs.canvas.removeEventListener("mousemove", (e) => {
      this.mouseMove(e);
    });

    if (this.gl) {
      this.gl.getExtension("WEBGL_lose_context").loseContext();
      //this.gl.deleteBuffer()
      this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
      this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

      this.gl.bindTexture(this.gl.TEXTURE_2D, null);
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
      this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, null);
      this.gl.bindRenderbuffer(this.gl.RENDERBUFFER, null);
      this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);

      this.gl.enable(this.gl.DEPTH_TEST);
      this.gl.enable(this.gl.CULL_FACE);
      this.gl.frontFace(this.gl.CCW);
      this.gl.cullFace(this.gl.BACK);
    }

    if (this.gkview) {
      this.gkview.onUpdate = null;
    }
    this.gl = null;
    this.gkview = null;
    this.scene = null;
    this.camera = null;
    this.interactionController = null;
    this.earth = null;
    this.movementModel = null;
  },
};
</script>

<style lang="scss" scoped>
.globe {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $z-globe;
  pointer-events: auto;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  canvas {
    position: relative;
    width: 100%;
    height: 100%;
    //background-color: ;
    opacity: 0;
    transition: opacity 0.5s cubic-bezier($ease-out-cubic);
    &.draw {
      opacity: 1;
    }
  }
  &.draggable {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  .hitTest {
    position: absolute;
    right: 0px;
    bottom: 0px;

    padding: 20px;
    background-color: white;
    padding-left: 0;
    width: 100px;
    p {
      white-space: nowrap;
      text-align: right;
      color: black;
      font-size: 1.1rem;
    }
  }
  .callouts {
    $variation: 3;

    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    // We reset the z-index of callouts here, so we can use huge indexes
    // ... for pins further down without painting of top of the menu, etc.
    z-index: 0;

    // Enables z-index using translateZ
    transform-style: preserve-3d;

    opacity: 0;
    transition: opacity 0.5s cubic-bezier($ease-out-cubic);
    &.draw {
      opacity: 1;
    }

    /deep/ .pin-callout {
      top: 0;
      left: 0;
      position: absolute;
      will-change: transform;
      // By setting the Z translation in the transform, they pins naturally swap z-order without
      // actually getting bigger (Since we don't have any perspective set).
      // That way we don't have to update the z-index on the pins as they rotate!
      transform: translate3d(
          calc(var(--x) - 50%),
          calc(var(--y) - 50%),
          calc(var(--scale) * 1px)
        )
        scale(calc(var(--scale) / #{10000}));

      // EDIT: Z-index is not needed! We just set the 3D Z translation in the transform above
      // Z-index is based on scale (a number between 0 and 10000). Note below we move larger pins
      // ...further down in z-index so they never fully cover a smaller pin
      //z-index: var(--scale);

      transition: opacity 0.2s cubic-bezier($ease-in-cubic);

      &.hidden {
        display: none;
      }

      .callout-container {
        --offset-x: 0;
        --offset-y: 0;
        --offset-factor: 1;
        --offset-amount: 50px;

        $relativeRadius: 0.035;
        $radius: calc(#{$relativeRadius * 4} * (100vh - #{$nav-height * 2}));

        position: absolute;
        top: 50%;
        left: 50%;
        width: $radius;
        height: $radius;
        border-radius: 50%;

        //hsl(206, 94%, calc(var(--scale) * 0.08% + 30%));
        background-color: rgba(255, 255, 255, 0.4);
        filter: brightness(calc(var(--scale) * 0.008% + 30%));
        will-change: box-shadow;
        transition: transform 0.35s cubic-bezier($ease-out-cubic),
          background 0.35s cubic-bezier($ease-out-cubic),
          box-shadow 0.35s cubic-bezier($ease-out-cubic);
        pointer-events: auto;
        cursor: pointer;
        //border: 6px solid white;
        box-shadow: inset 0 0 0 6px white;

        img {
          background-color: white;
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          transform: scale(0.8);
          pointer-events: none;
          user-select: none;
          opacity: 0;
          transition: opacity 0.35s cubic-bezier($ease-out-cubic);
        }
      }

      .callout-id {
        font-size: 80px;
        color: yellow;
      }

      .callout-container {
        transform: translate(
            calc(
              -50% + var(--offset-x) * var(--offset-amount) * var(--offset-factor)
            ),
            calc(
              -50% + var(--offset-y) * var(--offset-amount) * var(--offset-factor)
            )
          )
          scale(0.25);
      }

      &:hover,
      &.selected {
        // Set a huge Z position to make it appear on top
        transform: translate3d(
            calc(var(--x) - 50%),
            calc(var(--y) - 50%),
            9999px
          )
          scale(calc(var(--scale) / 10000));
        .callout-container {
          background-color: rgba(255, 255, 255, 0);
          img {
            opacity: 1;
          }
        }
      }
      &:hover {
        .callout-container {
          transform: translate(
              calc(
                -50% + var(--offset-x) * var(--offset-amount) * var(--offset-factor)
              ),
              calc(
                -50% + var(--offset-y) * var(--offset-amount) * var(--offset-factor)
              )
            )
            scale(0.5) !important;
          //box-shadow: inset 0 0 0 0.6px white;
          box-shadow: inset 0 0 0 4px white;
          //border: 4px solid white;
        }
      }
      &.selected {
        .callout-container {
          transform: translate(
              calc(
                -50% + var(--offset-x) * var(--offset-amount) * var(--offset-factor)
              ),
              calc(
                -50% + var(--offset-y) * var(--offset-amount) * var(--offset-factor)
              )
            )
            scale(1) !important;
          //box-shadow: inset 0 0 0 0.35px white;
          box-shadow: inset 0 0 0 2px white;
          //border: 2px solid white;
        }
      }
      &.no-pointer-events {
        .callout-container {
          pointer-events: none;
        }
      }
    }
    &.squareCallouts {
      /deep/ .pin-callout {
        .callout-container {
          $relativeRadius: 0.035;
          $radius: calc(#{$relativeRadius * 4} * (100vh - #{$nav-height * 2}));

          width: $radius;
          height: $radius;
          border-radius: 10%;
          img {
            border-radius: 8%;
          }
        }
      }
    }
    &:not(.showCallouts) {
      /deep/ .pin-callout {
        transition: opacity 0.2s cubic-bezier($ease-out-cubic);
        opacity: 0;
      }
    }
  }

  /*&:before{
    content: "";
    display: block;
    padding-bottom: 100%;
  }*/
  &.locked {
    //pointer-events: none;
    cursor: pointer;
    /deep/ .pin-callout {
      .callout-container {
        pointer-events: none;
      }
    }
  }
}
.vue-dat-gui {
  position: absolute;
  left: 0;
  top: auto;
  bottom: 20px;
  /deep/ input {
    height: 100%;
    user-select: none;
  }
  /deep/ span {
    user-select: none;
  }
  /deep/ .toggle-button {
    user-select: none;
  }
}
.fps {
  position: absolute;
  bottom: 8rem;
  left: 0;
  color: white;
}
</style>
