<template>
  <nuxt-link
    :to="$urlResolver(href)"
    v-if="linkType === 'story'"
    :target="target"
  >
    <slot />
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path
        d="M18 8L16.6 9.4L18.2 11H2V13H18.2L16.6 14.6L18 16L22 12L18 8Z"
      ></path>
    </svg>
  </nuxt-link>
  <a v-else-if="linkType === 'email'" :href="`mailto:${href}`"
    ><slot />
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path
        d="M11.6 8.4L14.2 11H2V13H14.2L11.6 15.6L13 17L18 12L13 7L11.6 8.4Z"
      ></path>
      <path d="M22 6H20V18H22V6Z"></path>
    </svg>
  </a>
  <a v-else :href="href" :target="target" rel="noopener"
    ><slot />
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path
        d="M11.6 8.4L14.2 11H2V13H14.2L11.6 15.6L13 17L18 12L13 7L11.6 8.4Z"
      ></path>
      <path d="M22 6H20V18H22V6Z"></path>
    </svg>
  </a>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "SbLink",
  props: {
    attrs: {
      type: Object,
      required: true
    }
  },
  setup({ attrs }) {
    const linkType = ref(attrs.linktype);
    const href = ref(attrs.href);
    const target = ref(attrs.target);
    return {
      linkType,
      href,
      target
    };
  }
});
</script>
