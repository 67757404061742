export const hexToRgb = (hex) => {
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return [(c>>16)&255, (c>>8)&255, c&255];
  }
  throw new Error('Bad Hex');
}

// Found here: http://wip.davidlochhead.xyz/update/2017/10/12/random-position-on-the-surface-of-a-sphere.html
export const getUniformPositions = (howMany, radius) => {
  var vectors = []
  var inc = Math.PI * (3 - Math.sqrt(5))
  var x = 0
  var y = 0
  var z = 0
  var r = 0
  var phi = 0
  for (var k = 0; k < howMany; k++) {
      var off = 2 / howMany
      y = k * off - 1 + off / 2
      r = Math.sqrt(1 - y * y)
      phi = k * inc
      x = Math.cos(phi) * r
      z = (0, Math.sin(phi) * r)
      x *= radius
      y *= radius
      z *= radius
      vectors.push([x, y, z])
  }
  return vectors
}

export const setVectorArray = (fromVector) => {
  let array = []
  for (let i = 0; i < fromVector.length; i++) {
    for (let u = 0; u < fromVector[i].length; u++) {
      array.push(fromVector[i][u])
    }
  }
  return array
}
export const normalFromMat4 = (a) => {
  // Calculates a 3x3 normal matrix (transpose inverse) from the 4x4 matrix
  // https://github.com/oframe/ogl/blob/master/src/math/functions/Mat3Func.js

  // a = 4x4matrix
  // out = 3x3matrix

  let a00 = a[0],
      a01 = a[1],
      a02 = a[2],
      a03 = a[3];
  let a10 = a[4],
      a11 = a[5],
      a12 = a[6],
      a13 = a[7];
  let a20 = a[8],
      a21 = a[9],
      a22 = a[10],
      a23 = a[11];
  let a30 = a[12],
      a31 = a[13],
      a32 = a[14],
      a33 = a[15];

  let b00 = a00 * a11 - a01 * a10;
  let b01 = a00 * a12 - a02 * a10;
  let b02 = a00 * a13 - a03 * a10;
  let b03 = a01 * a12 - a02 * a11;
  let b04 = a01 * a13 - a03 * a11;
  let b05 = a02 * a13 - a03 * a12;
  let b06 = a20 * a31 - a21 * a30;
  let b07 = a20 * a32 - a22 * a30;
  let b08 = a20 * a33 - a23 * a30;
  let b09 = a21 * a32 - a22 * a31;
  let b10 = a21 * a33 - a23 * a31;
  let b11 = a22 * a33 - a23 * a32;

  // Calculate the determinant
  let det = b00 * b11 - b01 * b10 + b02 * b09 + b03 * b08 - b04 * b07 + b05 * b06;

  if (!det) {
      return null;
  }
  det = 1.0 / det;

  const out = []

  out[0] = (a11 * b11 - a12 * b10 + a13 * b09) * det;
  out[1] = (a12 * b08 - a10 * b11 - a13 * b07) * det;
  out[2] = (a10 * b10 - a11 * b08 + a13 * b06) * det;

  out[3] = (a02 * b10 - a01 * b11 - a03 * b09) * det;
  out[4] = (a00 * b11 - a02 * b08 + a03 * b07) * det;
  out[5] = (a01 * b08 - a00 * b10 - a03 * b06) * det;

  out[6] = (a31 * b05 - a32 * b04 + a33 * b03) * det;
  out[7] = (a32 * b02 - a30 * b05 - a33 * b01) * det;
  out[8] = (a30 * b04 - a31 * b02 + a33 * b00) * det;

  return out;
}