<template>
  <w-resolve-link
    ref="button"
    :class="[$options.name, { inverted: inverted }]"
    :slug="button.reference"
  >
    <span>{{ button.text }}</span>
  </w-resolve-link>
</template>

<script>
import WResolveLink from "@/components/wrappers/W-ResolveLink.vue";
export default {
  name: "I-Button",
  components: {
    WResolveLink,
  },
  props: {
    button: {
      type: Object,
      required: true,
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
a {
  @extend %button;
}
</style>
