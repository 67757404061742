<template>
  <div
    class="globe-placeholder"
    :class="`globe-placeholder--${name}`"
    ref="placeholder"
  >
    <!-- GLOBE PLACEHOLDER: {{name}} -->
  </div>
</template>

<script>
export default {
  name: "TerraXGlobePlaceholder",
  props: {
    name: {
      type: String,
      required: true,
      validator: value => {
        return (
          ["about", "filters-open", "filters-closed"].indexOf(value) !== -1
        );
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      const placeholder = this.$refs.placeholder.getBoundingClientRect();

      const left = placeholder.left;
      const width = placeholder.width;
      const windowWidth = window.innerWidth;

      const data = {};
      (data[`${this.name}-left`] = left / windowWidth),
        (data[`${this.name}-width`] = width / windowWidth),
        this.$store.commit("terrax/setGlobePositionLayoutFind", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.globe-placeholder {
  width: 100%;
  visibility: hidden;
  // height: 1rem; // for dev
  // background-color: purple; // for dev
}
</style>
