export const state = () => ({
  isLocked: true,
  hasScrolled: false,
})

export const mutations = {
  toggle(state) {
    state.isLocked = !state.isLocked
  },
  unlock(state) {
    state.isLocked = false;
  },
  lock(state) {
    state.isLocked = true;
  },
  scroll(state) {
    state.hasScrolled = true;
  }
}
