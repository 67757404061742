<template>
  <svg viewBox="0 0 64 45">
    <path d="M5.0388 4.3428c-.376 0-.752.2995-.752.7487v34.8919c0 .3743.3007.7487.752.7487h53.9224c.3761 0 .7521-.2995.7521-.7487V5.0166c0-.3743-.3008-.7487-.7521-.7487H5.0388v.0749zM58.9612 45H5.0388C2.2562 45 0 42.7537 0 39.9834V5.0166C0 2.2463 2.2562 0 5.0388 0h53.9224C61.7438 0 64 2.2463 64 5.0166v34.8919C64 42.7537 61.7438 45 58.9612 45z" fill="#031F35">
    </path>
    <path d="M20.6813 16.9218c-.3008.2995-.6017.599-.9777.7487-.376.1498-.8273.2247-1.3537.2995-.5264 0-.9777.0749-1.4289.0749h-2.933v-5.0166h3.2338c.4513 0 .9025 0 1.3537.0749.4513.0748.9025.1497 1.2033.3743.376.1498.6769.4493.9025.7488.2256.2995.376.7487.376 1.3477 0 .5242-.1504 1.0483-.376 1.3478zm4.2115 2.6206c.8273-1.1231 1.2033-2.396 1.2033-3.9684 0-1.2728-.2256-2.396-.6769-3.2196-.4512-.8985-1.0528-1.5724-1.8049-2.0965-.7521-.5241-1.6545-.8985-2.7074-1.1231-1.0529-.2247-2.1058-.2995-3.2338-.2995H9.0996v22.2379h4.8884v-8.9101h2.4066l4.6627 8.9101h5.866l-5.6404-9.3594c1.6545-.3744 2.8578-1.0482 3.6099-2.1714zM33.9929 21.9384h9.7015v-4.4925h-9.7015v-4.1181h10.2279V8.7604H29.1045v22.2379h15.718v-4.4925H33.9929v-4.5674zM50.0121 22.5374c-.2257.1498-.3761.2995-.5265.5242-2.2562 3.4442 1.4289 7.1131 4.8884 4.8669.2256-.1498.376-.2995.5264-.5242 2.2562-3.4442-1.4289-7.1131-4.8883-4.8669zM50.0121 11.9051c-.2257.1498-.3761.2995-.5265.5242-2.2562 3.4442 1.4289 7.1131 4.8884 4.8669.2256-.1498.376-.2995.5264-.5242 2.2562-3.4442-1.4289-7.1131-4.8883-4.8669z" fill="#031F35">
    </path>
    <path d="M40.1599 33.2446h-5.1892v2.3211h5.1892v-2.3211z" fill="#2BBAE0">
    </path>
    <path d="M19.4783 33.2446h-5.1892v2.3211h5.1892v-2.3211z" fill="#DBA638">
    </path>
    <path d="M24.5925 33.2446h-5.1892v2.3211h5.1892v-2.3211z" fill="#4A9E45">
    </path><path d="M34.9704 33.2446h-5.1892v2.3211h5.1892v-2.3211z" fill="#E8402E"></path><path d="M14.2888 33.2446H9.0996v2.3211h5.1892v-2.3211z" fill="#E5243D"></path><path d="M45.2741 33.2446H40.085v2.3211h5.1891v-2.3211z" fill="#F7C212"></path><path d="M29.782 33.2446h-5.1892v2.3211h5.1892v-2.3211z" fill="#C41F2E"></path><path d="M29.782 35.5657h-5.1892v2.3212h5.1892v-2.3212z" fill="#000"></path><path d="M50.4626 35.5657h-5.1892v2.3212h5.1892v-2.3212z" fill="#F59E29"></path><path d="M34.9704 35.5657h-5.1892v2.3212h5.1892v-2.3212z" fill="#1C94D1"></path><path d="M45.2741 35.5657H40.085v2.3212h5.1891v-2.3212z" fill="#BA872B"></path><path d="M55.6521 35.5657h-5.1892v2.3212h5.1892v-2.3212z" fill="#DB1769"></path><path d="M24.5925 35.5657h-5.1892v2.3212h5.1892v-2.3212z" fill="#63B247"></path><path d="M50.4626 33.2446h-5.1892v2.3211h5.1892v-2.3211z" fill="#A11C45"></path><path d="M55.6521 33.2446h-5.1892v2.3211h5.1892v-2.3211z" fill="#ED6B2E"></path><path d="M19.4783 35.5657h-5.1892v2.3212h5.1892v-2.3212z" fill="#056B9C"></path><path d="M14.2888 35.5657H9.0996v2.3212h5.1892v-2.3212z" fill="#17476B"></path><path d="M40.1599 35.5657h-5.1892v2.3212h5.1892v-2.3212z" fill="#3D8045"></path></svg>
</template>

<script>

export default {
  name: "LogoReTv",
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
</style>