import Vue from "vue";
import VueGtag from "vue-gtag";

export default ({ app, isServer, $config }, inject) => {
  let gTagCreated = false

  if (!$config.GA_MEASUREMENT_ID) {
    console.warn('GA_MEASUREMENT_ID env-variable is missing')
  }

  const createGtag = () => {
    if (gTagCreated) {
      return
    }
    gTagCreated = true

    // NB: We should NOT give app.router to vueGtag, because GA4 properties automatically detects pageviews with it's enhanced measurement thing
    Vue.use(VueGtag, {
      config: { id: $config.GA_MEASUREMENT_ID },
      enabled: isAccepted,
      onReady: () => {
        // this event will run even if enabled is false - but it worn't track if the enabled event is false!
        // console.log('GTAG', 'setup', 'ready!')

        // TRACK FIRST PAGEVIEW MANUALLY
        // we have to manually track the first page view - everything else will be tracked automatically by GA4 Enhanced Measurement
        // this hit will only get sent, if VueGtag enabled = true
        const path = app.router.currentRoute.path
        Vue.$gtag.pageview({
          page_path: path,
        })
      },
    })

  }

  const cookie = Vue.$cookie.get($config.COOKIE_SETTINGS_NAME)

  const isAccepted = cookie === '1'
  switch(cookie) {
    case '1':
      console.log('COOKIES STATUS: ACCEPTED')
      break;
    case '0':
      console.log('COOKIES STATUS: DENIED')
      break;
    default:
      console.log('COOKIES STATUS: HAS NOT BEEN SET')
      break;
  }

  if (!isAccepted) {
    window[`ga-disable-${$config.GA_MEASUREMENT_ID}`] = true
  } else {
    window[`ga-disable-${$config.GA_MEASUREMENT_ID}`] = false
    createGtag()
  }

  // HANDLE ACCEPT AND DECLINE EVENTS FROM COOKIE POPUP
  window.onNuxtReady(() => {
    const $nuxt = window.$nuxt
    // listen to 'accept' and 'decline' cookie events from $nuxt
    $nuxt.$on('cookie-accept', () => {
      console.log('GTAG', 'ON ACCEPT')
      createGtag()
      Vue.$gtag.optIn()
      window[`ga-disable-${$nuxt.$config.GA_MEASUREMENT_ID}`] = false
      // send pageview on accept
      const path = app.router.currentRoute.path
      Vue.$gtag.pageview({
        page_path: path,
      })
    })
    $nuxt.$on('cookie-decline', () => {
      console.log('GTAG', 'ON DECLINE')
      if (gTagCreated) {
        Vue.$gtag.optOut()
      }
      window[`ga-disable-${$nuxt.$config.GA_MEASUREMENT_ID}`] = true
    })
  })

}

