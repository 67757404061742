export const state = () => ({
  currentRoutePath: undefined,
  hasBackUrl: false,
  baseUrl: "/",
})

export const mutations = {
  setCurrentRoutePath(state, value) {
    // console.log('MUTATION','setCurrentRoutePath', value)
    state.currentRoutePath = value
  },
  setHasBackUrl(state, value) {
    state.hasBackUrl = value;
  }
}

export const getters = {
  getCurrentRoutePath: (state) => () => {
    return state.currentRoutePath
  },
  getBaseUrl: (state) => () => {
    return state.baseUrl
  },
}
