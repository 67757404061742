export const state = () => ({
  menuSidebarIsOpen: false
})

export const mutations = {
  toggle(state) {
    state.menuSidebarIsOpen = !state.menuSidebarIsOpen
  },
  close(state) {
    state.menuSidebarIsOpen = false
  },
  open(state) {
    state.menuSidebarIsOpen = true
  }
}

export const getters = {
  isMenuOpen: (state) => (props) => {
    return state.menuSidebarIsOpen
  }
}