<template>
  <div :class="['cookiePopup', { 'is-editor': isEditor }]">
    <transition name="fade-up">
      <div v-if="cookiePopupVisible">
        <p>
          {{ message }} –&nbsp;
          <w-resolve-link :slug="cookiePolicyPage">
            {{ buttonTextReadMore }}
          </w-resolve-link>
        </p>
        <div class="buttons">
          <button class="cookie-primary" @click="accept">
            {{ buttonTextAccept }}
          </button>
          <button class="cookie-secondary" @click="decline">
            {{ buttonTextDecline }}
          </button>
          <!-- <button @click="deleteCookie">Delete cookie</button> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import WResolveLink from "@/components/wrappers/W-ResolveLink.vue";
export default {
  name: "I-CookiePopup",
  components: {
    WResolveLink,
  },
  data() {
    return {
      gdprContent: {},
      cookiePopupVisible: false,
      cookieSettingsName: this.$config.COOKIE_SETTINGS_NAME,
      // cookie expire time is set to 365 days (according to the ePrivacy Directive, they should not last longer than 12 months - https://gdpr.eu/cookies/#:~:text=All%20persistent%20cookies%20have%20an,you%20do%20not%20take%20action. )
      expireTime: 365,
      isEditor: false,
    };
  },
  computed: {
    gdprFields() {
      return this.$store.state.gdpr.content;
    },
    message() {
      return this.gdprFields.cookieText;
    },
    buttonTextAccept() {
      return this.gdprFields.buttonTextAccept || "Accept";
    },
    buttonTextDecline() {
      return this.gdprFields.buttonTextDecline || "Decline";
    },
    buttonTextReadMore() {
      return this.gdprFields.readMoreButtonText || "Read more";
    },
    cookiePolicyPage() {
      return this.gdprFields.cookiePolicyPage;
    },
  },
  mounted() {
    this.checkIfEditor();
    const cookie = this.$cookie.get(this.cookieSettingsName);
    switch (cookie) {
      case "1":
        // COOKIES STATUS: ACCEPTED
        break;
      case "0":
        // COOKIES STATUS: DENIED
        break;
      default:
        // COOKIES STATUS: HAS NOT BEEN SET
        setTimeout(() => {
          this.cookiePopupVisible = true;
        }, 1000);
        break;
    }
  },
  created() {
    this.$nuxt.$on("cookie-popup-open", () => {
      this.cookiePopupVisible = true;
    });
  },
  beforeDestroy() {
    this.$nuxt.$off("cookie-popup-open");
  },
  methods: {
    // NB: 'cookie-accept' and 'cookie-decline' $nuxt events are picked up by plugins like gtag-setup.js
    accept() {
      this.$nuxt.$emit("cookie-accept");
      this.$cookie.set(this.cookieSettingsName, 1, this.expireTime);
      this.hideCookiePopup();
    },
    decline() {
      this.$nuxt.$emit("cookie-decline");
      this.$cookie.removeAll();
      this.$cookie.set(this.cookieSettingsName, 0, this.expireTime);
      this.hideCookiePopup();
    },
    deleteCookie() {
      this.$cookie.remove(this.cookieSettingsName);
    },
    hideCookiePopup() {
      setTimeout(() => {
        this.cookiePopupVisible = false;
      }, 100);
    },
    checkIfEditor() {
      var url =
        window.location != window.parent.location
          ? document.referrer
          : document.location.href;
      if (
        url.indexOf("/editor?path=") > -1 ||
        url.indexOf("app.storyblok.com") > -1
      ) {
        this.isEditor = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.is-editor {
  display: none;
}
.cookiePopup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
  > * {
    margin-bottom: 3em;
  }
  @extend %padding-left-right-x1;
  > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: $color-blue;
    padding: 1em;
    border: 1px solid $color-blue;
    flex-direction: column;
    @media (min-width: $break-mobile-landscape) {
      flex-direction: row;
      padding: 0em 2em;
    }
  }
  p,
  .buttons {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    @media (min-width: $break-mobile-landscape) {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
    }
  }
  p {
    @media (min-width: $break-mobile-landscape) {
      margin-right: 2em;
    }
  }
  a {
    font-weight: bold;
  }
  .buttons {
    display: flex;
  }
  button {
    @extend %button;
    &:not(:last-child) {
      margin-right: 0.5em;
      @media (min-width: $break-mobile-landscape) {
        margin-right: 1em;
      }
    }
    &.cookie-primary {
      border-width: 2px;
    }
    &.cookie-secondary {
      background-color: $color-grey-light;
    }
  }
}

/* CUSTOM FADE TRANSITION */
.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.35s, transform 0.35s cubic-bezier($ease-out-cubic);
}
.fade-up-enter, .fade-up-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(50%);
}
</style>
