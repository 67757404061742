var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',{staticClass:"loading-bar",style:({
    '--duration-started': _vm.durationStarted + 'ms',
    '--duration-ended': _vm.durationEnded + 'ms',
  })},[_c('div',{staticClass:"loading-bar__progress",class:{
      'loading-bar__progress--inverted': _vm.$store.state.layout == 'LayoutTerraXFind',
      'loading-bar__progress--started': _vm.started,
      'loading-bar__progress--ended': _vm.ended,
    }})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }