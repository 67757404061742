<template>
  <div class="no-results">
    <transition name="fade-up">
      <div class="clear-filter" v-if="showNoResults">
        <p class="lead">No {{ resultType }} found</p>
        <div class="content">
          You may want to
          <button @click="clearFilters">Clear filters</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "TerraXNoResultsGlobe",
  computed: {
    isGlobeOpen() {
      return this.$store.getters["terrax/isGlobeOpen"]();
    },
    resultType() {
      let type = this.$store.getters["terrax/getType"]();
      switch (type) {
        case "projects":
          return "projects";
        case "investors":
          return "investors";
        default:
          return "results";
      }
    },
    filteredData() {
      return this.$store.getters["terrax/filteredData"]();
    },
    showNoResults() {
      const isAboutPage = this.$store.state.layout === "LayoutTerraX";
      return this.filteredData.output.length < 1 && !isAboutPage;
    },
  },
  methods: {
    clearFilters() {
      this.$store.dispatch("terrax/clearFilters");
    },
    clearSearch() {
      this.$store.dispatch("terrax/clearSearch");
    },
  },
};
</script>

<style lang="scss" scoped>
.lead {
  @extend %headline4;
  margin-bottom: 20px;
}
.content {
  @extend %body3;
}
.clear-filter {
  text-align: center;
  background-color: $color-blue;
  border: 1px solid #fff;
  position: absolute;
  top: calc(50% - 65px);
  left: calc(50% - 172px);
  transform: scale(0.8);
  height: 130px;
  width: 284px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  @media (min-width: $break-mobile-landscape) {
    transform: scale(1);
    left: calc(50% - 142px);
  }
  & button {
    @extend %body3;
    margin: 0 2rem 0 0.8rem;
    color: #fff;
    opacity: 0.5;
    cursor: pointer;
    text-decoration: underline;
    text-transform: uppercase;
  }
}
</style>
