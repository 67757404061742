<template>
  <WMaxWidth :class="parentHasLayoutAstra ? 'bg-color-dark' : 'bg-color-white'">
    <div class="nav-topbar">
      <div class="logo">
        <nuxt-link
          class="logo__link"
          :to="$urlResolver($config.frontPage)"
          @click.native="$emit('close')"
        >
          <img
            :src="
              parentHasLayoutAstra || parentHasLayoutTerraX
                ? '/icons/astra/logo-sustainable-markets-astra-version-inverted.png'
                : '/icons/logo-sustainable-markets-seal-version.jpg'
            "
            alt="Sustainable Markets logo"
            class="logo__image"
          />
          <!-- 
            QUICK FIX: New logo has text in image, so we don't need the text -->
          <!--
          <span class="logo__text"
            ><strong>Sustainable Markets Initiative</strong></span
          >
          -->
        </nuxt-link>
      </div>
      <div class="burger">
        <button
          :class="['burger-button', { active: menuOpen }]"
          @click="$emit('burger-click')"
          :aria-label="!menuOpen ? 'Open main menu' : 'Close main menu'"
        >
          <span>{{ !menuOpen ? "Menu" : "Close" }}</span>
          <div
            :class="['burger__icon', { 'burger__icon--open': menuOpen }]"
          ></div>
        </button>
      </div>
    </div>
  </WMaxWidth>
</template>

<script>
import WMaxWidth from "@/components/wrappers/W-MaxWidth.vue";
export default {
  name: "W-NavTopbar",
  components: {
    WMaxWidth
  },
  data() {
    return {
      screenWidth: null
    };
  },
  props: {
    menuOpen: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    },
    hasLayoutAstra: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    parentHasLayoutAstra() {
      if (this.$store.state.layout === "LayoutAstra") {
        return !this.hasLayoutAstra;
      }
    },
    parentHasLayoutTerraX() {
      if (
        this.$store.state.layout === "LayoutTerraX" ||
        this.$store.state.layout === "LayoutTerraXFind"
      ) {
        // There is a different logo for desktop and mobile in LayoutTerraX & LayoutTerraXFind
        return !this.hasLayoutTerraX && this.screenWidth >= 880;
      }
    },
    baseUrl() {
      return this.$store.getters["pages/getBaseUrl"]();
    }
  },
  methods: {
    updateScreenWidth() {
      if (process.client) {
        this.screenWidth = window.innerWidth;
      }
    }
  },
  mounted() {
    this.updateScreenWidth();
    window.addEventListener("resize", this.updateScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth);
  }
};
</script>

<style lang="scss" scoped>
.bg-color-dark {
  background-color: $color-astra-black;
  span {
    color: #fff;
  }
  .burger {
    &__icon {
      &:before,
      &:after {
        background-color: #fff;
      }
    }
  }
}
.bg-color-white {
  background-color: #fff;
}
.nav-topbar {
  height: $nav-height;
  display: flex;
  align-self: center;
  justify-content: space-between;
}
.logo {
  align-self: center;
  &__link {
    color: $color-blue;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.4s cubic-bezier($ease-out-cubic);
    height: 100%;
    cursor: pointer;
  }

  &__image {
    width: 15rem;
    height: auto;
    margin-right: 16px;
    transition: filter 0.4s 0s cubic-bezier($ease-out-cubic);
    filter: none;
  }
  &__text {
    width: 9em;
    line-height: 1.25em;
    @extend %body2;
    transition: color 0.4s 0s cubic-bezier($ease-out-cubic);
  }
}
.burger {
  align-self: center;
  &-button {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: content-box;
    z-index: 1;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    &.active {
      color: $color-blue;
    }
    span {
      opacity: 0.6;
      font-size: 1.4rem;
      padding-right: 2.4rem;
      display: none;
      @media (min-width: $break-nav) {
        display: block;
      }
    }
  }
  &__icon {
    position: relative;
    height: 100%;
    width: 2.4rem;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2.4rem;
      height: 0.2rem;
      background-color: $color-blue;
      top: 50%;
      left: 50%;
      transition: transform 0.35s cubic-bezier($ease-out-cubic);
    }
    &:before {
      transform: translate(-50%, -0.6rem);
    }
    &:after {
      transform: translate(-50%, 0.6rem);
    }
    &--open {
      &:before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
</style>
