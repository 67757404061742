var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:[
    'nav-primary',
    { 'folder-open': _vm.openFolder },
    _vm.parentHasLayoutAstra ? 'astra-theme' : ''
  ]},[_c('li',{staticClass:"reTv-list-item"}),_vm._v(" "),(_vm.baseUrl === '/')?_c('li',{staticClass:"nav-primary-item"},[_c('nuxt-link',{staticClass:"nav-primary-link",attrs:{"to":"/"}},[_c('span',[_vm._v("Home")])])],1):_c('li',{staticClass:"nav-primary-item"},[_c('nuxt-link',{staticClass:"nav-primary-link",attrs:{"to":_vm.baseUrl + 'home'}},[_c('span',[_vm._v("Home")])])],1),_vm._v(" "),_vm._l((_vm.nav),function(item){return _c('li',{key:item.id,staticClass:"nav-primary-item"},[(item.component !== 'WNavFolder')?_c('w-resolve-link',{staticClass:"nav-primary-link",attrs:{"target":_vm.setTarget,"slug":item.slug}},[_c('span',[_vm._v(_vm._s(item.title))]),_vm._v(" "),(item.slug.url)?_c('IconExternal',{staticClass:"link-external"}):_vm._e()],1):_vm._e(),_vm._v(" "),(
        item.slug && item.slug.cached_url && item.component === 'WNavFolder'
      )?_c('button',{class:[
        'nav-primary-folder is-link',
        { 'is-active': item._uid === _vm.openFolder }
      ]},[_c('w-resolve-link',{class:[
          'nav-primary-link',
          { 'is-active': item._uid === _vm.openFolder }
        ],attrs:{"slug":item.slug}},[_c('span',[_vm._v(_vm._s(item.title))])]),_vm._v(" "),_c('IconPlus',{staticClass:"folder-icon",class:{ open: item._uid === _vm.openFolder },nativeOn:{"click":function($event){return _vm.onFolderToggle(item._uid)}}})],1):_vm._e(),_vm._v(" "),(item.component === 'WNavFolder')?_c('button',{class:[
        'nav-primary-folder',
        { 'is-active': item._uid === _vm.openFolder }
      ],on:{"click":function($event){return _vm.onFolderToggle(item._uid)}}},[_c('span',[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('IconPlus',{staticClass:"folder-icon",class:{ open: item._uid === _vm.openFolder }})],1):_vm._e(),_vm._v(" "),_c('TransitionExpandHeight',{attrs:{"expanded":item._uid === _vm.openFolder}},[(item.component === 'WNavFolder')?_c('ul',{staticClass:"nav-primary-sub"},_vm._l((item.navList),function(el){return _c('li',{key:el.id,staticClass:"nav-primary-sub-item"},[(el.component !== 'WNavFolder')?_c('w-resolve-link',{staticClass:"nav-primary-sub-link",attrs:{"target":_vm.setTarget,"slug":el.slug}},[_c('span',[_vm._v(_vm._s(el.title))]),_vm._v(" "),(el.slug.url)?_c('IconExternal',{staticClass:"link-external"}):_vm._e()],1):_vm._e()],1)}),0):_vm._e()])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }