<template>
  <div class="article-video">
    <CompVideo :blok="blok" :animate="false"></CompVideo>
  </div>
</template>

<script>
import CompVideo from "@/components/comps/CompVideo";
export default {
  components: {
    CompVideo
  },
  props: {
    body: {
      type: Object,
      require: true
    }
  },
  computed: {
    blok() {
      return {
        label: "",
        videoUrl: this.body.videoUrl,
        videoPosterImage: this.body.posterImage,
        settings: []
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.article-video {
  & /deep/ .W-MaxWidth > div {
    padding: 0;
  }
  & /deep/ .W-Section.paddings {
    padding: 0;
  }
  margin-top: 4rem;
  margin-bottom: 4rem;
}
</style>
