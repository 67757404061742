<template>
  <div>
    <ILazyImage
      class="a-image"
      v-if="body.image.filename"
      :filename="body.image.filename"
      :alt="body.image.alt"
      sizes="(min-width: 600px) 80vw, 100vw"
      :ratioW="16"
      :ratioH="0"
      loading="eager"
    />
  </div>
</template>

<script>
import ILazyImage from "@/components/items/I-LazyImage.vue";
export default {
  components: {
    ILazyImage,
  },
  props: {
    body: {
      type: Object,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.a-image {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
</style>
