export default ({
  app,
  route
}, inject) => {
  const resolveUrl = (url, base) => {
    url += url.endsWith("/") ? "" : "/";
    if (url.startsWith("/")) {
      url = url.substring(1);
    }
    if (url.startsWith(app.$config.frontPage) && base === "/") {
      url = "";
    }
    return url
  }
  const checkPreview = () => {
    let base = "/"
    if (route.name === app.$config.previewPage) {
      base = "/" + app.$config.previewPage + "?path=";
    }
    return base
  }
  const urlResolver = (url) => {
    const base = checkPreview(url);
    const resolvedUrl = resolveUrl(url, base);

    return base + resolvedUrl
  }
  inject('urlResolver', urlResolver);
}
