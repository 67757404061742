<template>
  <component :is="'w-' + slug.linktype + '-link'" :slug="slug">
    <slot></slot>
  </component>
</template>

<script>
import WStoryLink from "@/components/wrappers/W-StoryLink.vue";
import WUrlLink from "@/components/wrappers/W-UrlLink.vue";
import WEmailLink from "@/components/wrappers/W-EmailLink.vue";

export default {
  name: "WResolveLink",
  components: {
    WStoryLink,
    WUrlLink,
    WEmailLink
  },
  props: {
    slug: {
      type: Object,
      require: true
    }
  },
  components: {
    WStoryLink,
    WEmailLink,
    WUrlLink
  }
};
</script>

<style lang="scss" scoped></style>
