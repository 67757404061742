<template>
  <div
    :class="[
      'layout-default',
      { ie: ie10 || ie11, ie10: ie10, ie11: ie11 },
      $store.state.layout,
      { 'has-from': $store.state.pages.hasBackUrl }
    ]"
    :has-layout-astra="hasLayoutAstra"
  >
    <WNav
      :nav="$store.state.navLinks"
      :footer="$store.state.footer.content"
      v-if="$store.state.footer && $store.state.footer.content"
    />
    <TerraXGlobePlaceholders />

    <!-- <transition name="page" mode="out-in">
      <component
        :is="nuxtWrapperComponent"
        :key="nuxtWrapperComponent"
        class="main-outer"
      >
        <Nuxt />
      </component>
    </transition> -->
    <Nuxt />
    <div v-if="initiatedGlobe" class="x-globe-wrap">
      <TerraXGlobePosition
        :class="['x-globe', { 'x-globe-show': isGlobeOpen && !isGlobeHidden }]"
      >
        <TerraXGlobe :visible="isGlobeOpen && !isGlobeHidden" />
      </TerraXGlobePosition>
    </div>

    <ICookiePopup component="ICookiePopup" />
    <WFooter
      v-if="$store.state.footer && $store.state.footer.content"
      :footer="$store.state.footer.content"
      :inverted="$store.state.layout == 'LayoutTerraXFind'"
    />
  </div>
</template>

<script>
import TerraXGlobePosition from "@/components/terra-x/TerraXGlobePosition";
import TerraXGlobe from "@/components/terra-x/globe/TerraXGlobe";

import TerraXGlobePlaceholders from "@/components/terra-x/TerraXGlobePlaceholders";
import WFooter from "@/components/wrappers/W-Footer.vue";
import WNav from "@/components/wrappers/W-Nav.vue";

import ICookiePopup from "@/components/items/I-CookiePopup.vue";

export default {
  name: "default",
  components: {
    TerraXGlobePosition,
    TerraXGlobe,
    TerraXGlobePlaceholders,
    WFooter,
    WNav,
    ICookiePopup
  },
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: "https://www.sustainable-markets.org" + this.$route.path
        }
      ]
    };
  },
  props: {
    hasLayoutAstra: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ie10: false,
      ie11: false,
      initiatedGlobe: false
    };
  },
  computed: {
    // nuxtWrapperComponent() {
    //   switch (this.$store.state.layout) {
    //     case "LayoutTerraXFind":
    //       return "TerraXNav";
    //     default:
    //       return "div";
    //   }
    // },
    isGlobeOpen() {
      return this.$store.getters["terrax/isGlobeOpen"]();
    },
    isGlobeHidden() {
      return this.$store.getters["terrax/getGlobeHide"]();
    },
    globeVisible() {
      return (
        (this.$store.state.layout == "LayoutTerraXFind" ||
          this.$store.state.layout == "LayoutTerraX") &&
        this.isGlobeOpen
      );
    },
    layout() {
      return this.$store.state.layout;
    }
  },
  mounted() {
    this.isIE();
  },
  watch: {
    layout: {
      immediate: true,
      handler: function(newLayout) {
        if (newLayout == "LayoutTerraXFind" || newLayout == "LayoutTerraX") {
          this.initiatedGlobe = true;
        } else {
          this.initiatedGlobe = false;
        }
      }
    }
  },
  methods: {
    isIE() {
      const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
      const msie = ua.indexOf("MSIE "); // IE 10 or older
      const trident = ua.indexOf("Trident/"); //IE 11
      this.ie10 = msie > 0;
      this.ie11 = trident > 0;
    }
  }
};
</script>

<style lang="scss">
.x-globe-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-globe-wrap;
  pointer-events: none;
}
.x-globe {
  opacity: 0;
  visibility: hidden;
  &-show {
    transition: opacity 250ms 500ms linear !important;
    opacity: 1;
    visibility: visible;
  }
}
.layout-default {
  z-index: 0;
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease;
  }
}
body .LayoutTerraXFind {
  .main-outer {
    min-height: 100vh;
  }
  color: white;
  // overflow: hidden; // Bug: Safari had overscroll
  @media (min-width: $break-nav) {
    & .logo__text {
      color: #fff;
    }
    & .burger-button:not(.active) span {
      color: #fff;
      .menuOpen & {
        color: $color-blue;
      }
    }
    & .burger__icon:not(.burger__icon--open) {
      &:before,
      &:after {
        background-color: #fff;
      }
    }
    & .nav {
      background-color: rgba(255, 255, 255, 0);
    }
    & .nav .bg-color-white {
      background-color: rgba(255, 255, 255, 0);
    }
  }
}
body .LayoutTerraX {
  .main-outer {
    min-height: 100vh;
  }
  @media (min-width: $break-nav) {
    & .logo__text {
      color: #fff;
    }
    & .nav {
      background-color: rgba(255, 255, 255, 0);
    }
    & .nav .bg-color-white {
      background-color: rgba(255, 255, 255, 0);
    }
  }
}

body .LayoutProject {
  .main-outer {
    min-height: 100vh;
  }
}
body .LayoutInvestor {
  .main-outer {
    min-height: 100vh;
  }
}

.LayoutNewsArticle {
  &:before {
    display: none;
  }
  & .nav.W-Nav {
    transition: none;
    background-color: $color-blue;

    & .bg-color-white {
      opacity: 0;
      .has-from & {
        opacity: 1;
      }
    }
  }
  &.has-from {
    & .nav.W-Nav {
      background-color: rgba(255, 255, 255, 0);
    }
  }
  & .W-Footer {
    display: none;
  }
}

.globe-enter-active {
  transition: opacity 0.5s;
}
.globe-enter {
  opacity: 0;
}
</style>
