import Vue from 'vue'
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
import {
  Mark
} from '@marvr/storyblok-rich-text-types';
import RichtextLink from '@/components/utils/richtextLink.vue'
import articleVideo from '@/components/utils/articleVideo.vue'
import articleList from '@/components/utils/articleList.vue'
import articleImage from '@/components/utils/articleImage.vue'
import articleBlockquote from '@/components/utils/articleBlockquote.vue'

Vue.use(VueRichTextRenderer, {
  resolvers: {
    components: {
      RichtextLink,
      articleList,
      articleVideo,
      articleImage,
      articleBlockquote
    },
    marks: {
      [Mark.LINK]: RichtextLink
    }
  },
})
