<template>
  <div :class="$options.name" :style="{'--ratio': ratio}">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "W-AspectRatio",
  components: {
  },
  props: {
    ratio: {
      type: String,
      required: false,
      default: '56.25%'
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.W-AspectRatio{
  position: relative;
  width: 100%;
  --ratio: 56.25%;
  &:before{
    content: "";
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: var(--ratio);
  }
}
</style>